import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import popoverEditButton from "../calendar/popover/popoverEditButton";
import fetchInfoMeeting from "../api/meetings/fetchInfoMeeting";
import EditEventViewForm from "../calendar/forms/EditEventViewForm";
import createFormElement from "../calendar/forms/createFormElement";
import createDateTimeInput from "../calendar/forms/createDateTimeInput";
import editMeeting from "../api/meetings/editMeeting";
import popoverDeleteButton from "../calendar/popover/popoverDeleteButton";
import createPopover from "../calendar/popover/createPopover";
import startDateInputListener from "../calendar/forms/listeners/startDateInputListener";
import {
  datePickerMomentFormat,
  dateTimeOutputFormat,
  daysOfWeek,
  eventColors,
  initialCalendarView,
  initialHeaderToolbarRight,
} from "../calendar/utilities";

$(document).ready(function() {
  var roomCalendars = []

  var buildingId = $('#schedule-building-select').val();
  var urlParams = window.location.pathname.split('/');

  let currentView;

  if (urlParams.length == 3 && urlParams[2] === 'schedule') {
    var url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
    var calendarEl = document.getElementById('admin-calendar');

    var adminCalendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: initialCalendarView,
      eventOverlap: false,
      moreLinkClick: "popover",
      views: {
        timeGrid: {
          dayMaxEventRows: 6 // adjust to 6 only for timeGridWeek/timeGridDay
        }
      },
      headerToolbar: {
        left: 'title',
        center: '',
        right: initialHeaderToolbarRight
      },
      selectable: true,
      selectOverlap: false,
      select: function(info) {
        newMeetingModal(info, adminCalendar)
      },
      dayHeaderFormat: {weekday: 'short', day: 'numeric' },
      weekends: true,
      nowIndicator: true,
      contentHeight: 'auto',
      eventDisplay: 'block', 
      dayMaxEventRows: 4,
      slotMinTime: '07:00:00',
      slotMaxTime: '20:00:00',
      eventDidMount: function(info) {
        info.el.onclick = function() {

          if (document.querySelector('.customer-popover')) {
            document.querySelector('.customer-popover').remove();
          }
          
          const bodyRect = document.body.getBoundingClientRect()
          const elemRect = info.el.getBoundingClientRect()
          const offsetY   = elemRect.top - bodyRect.top;
          const offsetX   = elemRect.left - bodyRect.left;

          const popover = createMeetingPopover({
            info, 
            meetingRoomColor: info.event.backgroundColor,
            offsetX,
            offsetY,
            calendar: adminCalendar
          });

          document.body.appendChild(popover);

          // on click away from info.el
          document.body.addEventListener('click', function(e) {
            if (!info.el.contains(e.target) && !popover.contains(e.target)) {
              popover.remove();
            }
          });         

        }
      },
      eventContent: function(arg) {
        const { room, employee } = arg.event.extendedProps

        if (currentView === 'dayGridMonth') {
          return {
            html: `<div style="background-color: ${arg.event.backgroundColor}">
                    <div class="mx-1">
                    ${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} to 
                    ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]},
                    ${room},
                    ${employee},
                    ${arg.event.title}
                    </div>
                  </div>`
          }
        }

        return {
          html: `<div style="padding-top: 5px">
                  <div class="mx-1">${room}</div>
                  <div class="mx-1">${arg.event.title}</div>
                  <div class="mx-1">${employee}</div>
                  <div class="mx-1">${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.start.getDay()]}</div>
                  <div class="mx-1"> to ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]}</div>
                </div>`
        }
      },
      viewDidMount: function(info) {
        if (info.view.type === 'timeGridDay') {
          const mainCalenderView = document.getElementById('admin-calendar').querySelector('.fc-view-harness');
          
          if (mainCalenderView) {
            mainCalenderView.style.display = 'none';
          }
        }
      },
      viewClassNames: function(info) {
          // alert(info.view.type)
          if (info.view.type !== currentView) {
            currentView = info.view.type;
            if (info.view.type === 'timeGridDay') {
              $('#calendar').addClass('day-view');
              $('#meeting-rooms-container').hide();
              if (roomCalendars.length === 0 ) {
                addRoomToCalendar();
                
                const mainCalenderView = document.getElementById('admin-calendar').querySelector('.fc-view-harness')
                if (mainCalenderView) {
                  mainCalenderView.style.display = 'none';
                }
              }
            } else if (info.view.type === 'timeGridWeek' || info.view.type === 'dayGridMonth' ) {
              const mainCalenderView = document.getElementById('admin-calendar').querySelector('.fc-view-harness')
              if (mainCalenderView) {
                mainCalenderView.style.display = 'block';
              }
              removeRoomToCalendar();
              if (roomCalendars.length !== 0 ) {
                var url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
                fetchMeetingsData(url, false, adminCalendar)
              }
              roomCalendars.splice(0, roomCalendars.length)
              $('#calendar').removeClass('day-view');
              $('#meeting-rooms-container').show();
            } else {
              $('#calendar').removeClass('day-view');
              $('#meeting-rooms-container').show();
            }
          }
      },
      datesSet: function(info){
        handleDatesSet(info.start, info.end);
      }
    });

    adminCalendar.render(); 

    fetchMeetingsData(url, true, adminCalendar)
  }

  $('#schedule-building-select').on('change', function() {
    buildingId = $('#schedule-building-select').val();
    url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
    fetchMeetingsData(url, true, adminCalendar);
    adminCalendar.changeView('timeGridWeek')
  });

  $('#meeting-rooms-container').on('change', 'input[type="checkbox"]', function() {
    let selectedMeetingRoomIds = '';
    let checkboxes = $(this).closest('#meeting-rooms-container').find('input[type="checkbox"]');
    
    selectedMeetingRoomIds = '';

    checkboxes.each(function() {
      if ($(this).is(':checked')) {
        selectedMeetingRoomIds += $(this).data('meeting-room-id') + ',';
      }
    });

    selectedMeetingRoomIds = selectedMeetingRoomIds.replace(/,$/, '');
    buildingId = $('#schedule-building-select').val();
    url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings?meeting_room_ids=' +  encodeURIComponent(selectedMeetingRoomIds)
    fetchMeetingsData(url, false, adminCalendar);
  });

  $('#add-new-meeting').on('click', function() {
    fetchInfoNewMeetingData().then(function(response) {
      createMeetingForm(response, null, adminCalendar);
    })
    .catch(function(error) {
      console.log(error)
    });
  })


  const deleteMeeting = async (info) => {
    return fetch('/api/v1/admin/schedules/meeting/' + info.event.extendedProps.meeting.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
  }
  
  function editMeetingModal(event, calendar) {
    fetchInfoMeeting(buildingId)
      .then((data) => {
        editMeetingForm(data.meeting_rooms, event, calendar);
      })
      .catch((error) => {
        console.log(error)
      });
    $('#meetingModal').modal('show')
  }
  
  function editMeetingForm(meeting_rooms, event, calendar) {
    const editForm = $('#form-new-meeting');
    editForm.empty();
    $('#memberModalLabel').text(event.extendedProps.meeting.building_name)
    
    const flexDiv = createFormElement('div', 'flex');
    
    EditEventViewForm({
      event,
      flexDiv,
    })
    
    const submitDiv = createFormElement('div', 'mt-4 d-flex justify-content-end');
    const submitBtn = createFormElement('button', 'btn btn-lg w-100 btn-sign-up btn-primary "', 'Edit');
    submitBtn.attr('id', 'submitEditMeetingBtn');
    submitDiv.append(submitBtn);
    
    editForm.append(flexDiv, submitDiv);
    
    $('#submitEditMeetingBtn').on('click', function(){
      $(this).html('Editing...');
      $(this).prop('disabled', true);
      if (validateMeetingForm()) {
        handleEditSubmit(event.extendedProps.meeting.id, calendar)
      } else {
        alert(' Please select all options ')
      }
    });
  }
  
  function handleEditSubmit(meetingId, calendar) {
    const formData = {
      start_date: moment($('#start-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat),
      end_date: moment($('#end-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat)
    }
    
    editMeetingData(meetingId, formData, calendar)
  }
  
  function editMeetingData(meetingId, formData, calendar) {
    url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
    
    editMeeting(meetingId, { meeting: formData })
      .then((_data) => {
        $('#meetingModal').modal('hide')
        fetchMeetingsData(url, false, calendar)
      })
      .catch((error) => {
        console.error('Error creating meeting:', error);
        alert('Error creating meeting. Please try again.');
        $('#meetingModal').modal('hide')
      })
  }
  
  const createMeetingPopover = ({
    info, 
    meetingRoomColor,
    offsetX,
    offsetY,
    calendar
  }) => {
    const popover = createPopover({
      info,
      meetingRoomColor,
      offsetY,
      offsetX
    });
    
    const editable = info.event.start.getTime() > Date.now() && info.event.end.getTime() > Date.now()
    
    if (editable) {
      const editBtn = popoverEditButton();
      editBtn.onclick = function () {
        popover.remove();
        
        editMeetingModal(info.event, calendar);
      }
      
      const deleteBtn = popoverDeleteButton();
      deleteBtn.onclick = async function () {
        try {
          deleteBtn.innerHTML = 'Deleting...';
          await deleteMeeting(info)
          
          if (calendar.el.id === 'admin-calendar') {
            fetchMeetingsData('/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings', false, calendar)
          } else {
            fetchMeetingsForRoom(
              `/api/v1/admin/buildings/${encodeURIComponent(buildingId)}/meetings?meeting_room_ids=${encodeURIComponent(info.event.extendedProps.meeting.meeting_room_id)}`,
              calendar
            )
          }
          
          popover.remove();
        } catch (error) {
          console.log(error)
        }
      }
      popover.appendChild(editBtn);
      popover.appendChild(deleteBtn);
    }
    return popover;
  }

  function fetchMeetingsForRoom(url, calendar) {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        updateCalendar(response.meetings, response.meeting_rooms, calendar)
      },
      error: function(error) {
        console.log(error)
      }
    })
  }
  

  function fetchMeetingsData(url, isMeetingRoom, calendar) { 
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        if (isMeetingRoom) {
          updateMeetingRooms(response.meeting_rooms);
        }
        updateCalendar(response.meetings, response.meeting_rooms, calendar)
      },
      error: function(error) {
        console.log(error)
      }
    })
  }

  function fetchInfoNewMeetingData() {
    return new Promise(function(resolve, reject) {
      buildingId = $('#schedule-building-select').val();
      url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/get_info_meeting'
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {
          resolve(response)
        },
        error: function(error) {
          reject(error)
        }
      })
    })
  }

  function newMeetingModal(info, calendar) {
    fetchInfoNewMeetingData().then(function(response) {
      createMeetingForm(response, info, calendar);
    })
    .catch(function(error) {
      console.log(error)
    });
    $('#meetingModal').modal('show')
  }


  function meetingFormOnDrag ({
    info, 
    isDayView, 
    calendar, 
    data,
    meetingRoomDiv,
    startDateDiv,
    endDateDiv
}) {
    // Drag timeslots event
    if (isDayView) {
      var findMeetingRoom 
      if (calendar.el.id === "admin-calendar") {
        findMeetingRoom = data.meeting_rooms[0]
      } else {
        findMeetingRoom = data.meeting_rooms.find(function (obj) {
          return obj.id === parseInt(calendar.el.id.replace("room-", ""), 10);
        })
      }

      $('#modal-body-schedule').empty()
      $('#memberModalLabel').append(` - ${findMeetingRoom.name}`)

      const meetingRoomSelect = createSelect('meeting_room_select', data.meeting_rooms, 'Room', 'd-none', (findMeetingRoom && findMeetingRoom.id) || "");
      meetingRoomDiv.append(meetingRoomSelect);

    } else {
      const meetingRoomLable = createFormElement('lable', null, 'Meeting room') 
      const meetingRoomSelect = createSelect('meeting_room_select', data.meeting_rooms, 'Room', '', 0);
      meetingRoomDiv.append(meetingRoomLable, meetingRoomSelect);
    }


    let startDate = moment(info.start)
    let endDate = moment(info.end)

    if (currentView === 'dayGridMonth') {
      const currentDate = moment()
      startDate = moment(info.start).set({ hour: currentDate.hour(), minute: 0 })
      endDate = startDate.clone().add(1, 'hour')
    }

    // const startDateLable = createFormElement('lable', 'd-block', moment(info.start).format('dddd Do, MMMM'))
    const startDateInput = createDateTimeInput('start-date-meeting', 'start-date', null, startDate) 
    const startDateLable = createFormElement('lable', 'flex-auto building-form', 'Start date') 
    startDateDiv.append(startDateLable, startDateInput);

    const endDateInput = createDateTimeInput('end-date-meeting', 'end-date', null, endDate) 
    const endDateLable = createFormElement('lable', null, 'End date') 
    endDateDiv.append(endDateLable, endDateInput);
    // const hoursLable = createFormElement('lable', 'd-block', moment(info.start).format('h:mm A') + ' - ' + moment(info.end).format('h:mm A')) 
    startDateDiv.append(startDateLable, startDateInput);
    
    startDateInputListener(startDateInput, endDateInput);
  }


  function fetchAvailableMeetingRooms(startDate, endDate, meetingRoomSelect, meetingRoomDiv) {
    const url =
      '/api/v1/admin/buildings/' +
      encodeURIComponent(buildingId) +
      '/available_meeting_rooms?start_date=' +
      encodeURIComponent(startDate) +
      '&end_date=' +
      encodeURIComponent(endDate)
    
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        meetingRoomSelect.empty();
        meetingRoomSelect.append(createFormElement('option', null, 'Room'));
        response.meeting_rooms.forEach(function(room) {
          const option = createFormElement('option', null, room.name);
          option.val(room.id);
          meetingRoomSelect.append(option);
        });
      },
      error: function(error) {
        console.log(error)
      }
    })
  }

  function onChangeDateRangeForAvailableMeetingRooms({
    startDateInput, endDateInput, meetingRoomSelect, meetingRoomDiv
  }) {
    return function() {
      const startDate = moment(startDateInput.val(), datePickerMomentFormat)
      let endDate = moment(endDateInput.val(), datePickerMomentFormat)
      const hasRange = endDate && startDate
  
      if (hasRange) {
        const isStartDateBeforeEndDate = startDate.isBefore(endDate)
        
        if (isStartDateBeforeEndDate) {
          fetchAvailableMeetingRooms(startDate, endDate, meetingRoomSelect, meetingRoomDiv)
        } else {
          const startDatePlusOneHour = startDate.add(1, 'hour')
          
          endDateInput.val(startDatePlusOneHour.format(datePickerMomentFormat))
          endDate = startDatePlusOneHour
          fetchAvailableMeetingRooms(startDate, endDate, meetingRoomSelect, meetingRoomDiv)
        }
      }
    }
  }

  function meetingFormOnAddBtn ({ data, meetingRoomDiv, startDateDiv, endDateDiv }) {
    const startDate = new Date()
    startDate.setHours(startDate.getHours() + 1)
    startDate.setMinutes(0)

    const endDate = new Date()
    endDate.setHours(endDate.getHours() + 2)
    endDate.setMinutes(0)

    const startDateInput = createDateTimeInput('start-date-meeting', 'start-date', '', startDate) 
    const startDateLable = createFormElement('lable', null, 'Start date') 
    startDateDiv.append(startDateLable, startDateInput);

    const endDateInput = createDateTimeInput('end-date-meeting', 'end-date', '', endDate) 
    const endDateLable = createFormElement('lable', null, 'End date') 
    endDateDiv.append(endDateLable, endDateInput);

    const meetingRoomSelect = createSelect('meeting_room_select', data.meeting_rooms, 'Room', '', 0);
    const meetingRoomLable = createFormElement('lable', null, 'Meeting room') 
    meetingRoomDiv.append(meetingRoomLable, meetingRoomSelect);

    fetchAvailableMeetingRooms(
      moment(startDate, datePickerMomentFormat),
      moment(endDate, datePickerMomentFormat),
      meetingRoomSelect,
      meetingRoomDiv
    )

    startDateInput.on('change', onChangeDateRangeForAvailableMeetingRooms({ startDateInput, endDateInput, meetingRoomSelect, meetingRoomDiv }))
    endDateInput.on('change', onChangeDateRangeForAvailableMeetingRooms({ startDateInput, endDateInput, meetingRoomSelect, meetingRoomDiv}))
  }

  // Created Meeting Form
  function createMeetingForm(data, info, calendar) {
    $('#form-new-meeting').empty();
    $('#memberModalLabel').text(data.building.name)

    const flexDiv = createFormElement('div', 'flex');

    const startDateDiv = createFormElement('div', 'flex-auto building-form');
    const endDateDiv = createFormElement('div', 'flex-auto building-form mt-4');
    const meetingRoomDiv = createFormElement('div', 'flex-auto building-form mb-4 mt-4');

    const addMeetingTriggerFromBtn = !info
    const isDayView = info && info.view.type === "timeGridDay"

    // Add meeting btn selected
    if (addMeetingTriggerFromBtn) {
      meetingFormOnAddBtn({
        data, meetingRoomDiv, startDateDiv, endDateDiv
      })
    } else {
      meetingFormOnDrag({
        info, isDayView, calendar, data, meetingRoomDiv, startDateDiv, endDateDiv
      })
    }

    const businessDiv = createFormElement('div', 'flex-row building-form mt-4');

    const selectContainer = createFormElement('div', 'flex-1');
    const businessLable = createFormElement('lable', null, 'Company')
    const businessSelect = createSelect('business_select', data.businesses, 'Company', '', 0);
    
    selectContainer.append(businessLable, businessSelect);
    
    businessDiv.append(selectContainer);

    const memberDiv = createFormElement('div', 'flex-auto building-form mt-4 d-none');
    const memberLable = createFormElement('lable', null, 'Member') 
    const memberSelect = createSelect('member_select', data.members, 'Employee', '', 0);
    memberDiv.append(memberLable, memberSelect);

    businessSelect.on('change', function() {

      memberDiv.removeClass('d-none');
      const companyMembers = data.members.filter(member => member.business_id === parseInt(this.value));
      
      if (companyMembers.length === 0) {
        memberSelect.empty();
        memberDiv.addClass('d-none');
      } else {
        memberSelect.empty();
        companyMembers.forEach(function(member) {
          const option = createFormElement('option', null, member.name);
          option.val(member.id);
          memberSelect.append(option);
        });
      }
      
    })

    const submitDiv = createFormElement('div', 'mt-4 d-flex justify-content-end');
    const submitBtn = createFormElement('button', 'btn btn-lg w-100 btn-sign-up btn-primary "', 'Book');
    submitBtn.attr('id', 'submitNewMeetingBtn');
    submitDiv.append(submitBtn);


    flexDiv.append(startDateDiv, endDateDiv, meetingRoomDiv, businessDiv, memberDiv);

    $('#form-new-meeting').append(flexDiv, submitDiv);

    $('#submitNewMeetingBtn').on('click', function(){
      $(this).html('Booking...');
      $(this).prop('disabled', true);
      if (validateMeetingForm()) {
        handleSubmit(calendar)
      } else {
        $(this).html('Book');
        $(this).prop('disabled', false);
        alert(' Please select all options ')
      }
    });
  }

  function createSelect(id, options, prompt, className, defaultValue) {
    const select = $('<select>').attr('id', id).addClass('form-control px-3 py-2 mt-1 w-100 ' + className);
    const defaultOption = createFormElement('option', null, prompt);
    defaultOption.val(defaultValue)
    select.append(defaultOption);
    $.each(options, function(_, item) {
      const option = createFormElement('option', null, item.name);
      option.val(item.id);
      select.append(option);
    });
    return select;
  }

  function handleSubmit(calendar) {
    const formData = {
      meeting_room_id: $('#meeting_room_select').val(),
      business_id: $('#business_select').val(),  
      member_id:$('#member_select').val(),
      start_date: moment($('#start-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat),
      end_date: moment($('#end-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat)
    };

    createMeeting(formData, calendar)
  }

  function createMeeting(formData, calendar) {
    $.ajax({
      url: '/api/v1/admin/schedules',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ meeting: formData }),
      success: function (response) {
        $('#meetingModal').modal('hide')
        addEventCalendar(response.meeting, calendar)
      },
      error: function (error) {
        console.error('Error creating meeting:', error);
        alert(`Error creating meeting: ${error.responseJSON.errors}`);
        $('#meetingModal').modal('hide')
      }
    });
  }

  function updateCalendar(meetings, rooms, calendar) {  
    calendar.getEvents().forEach(event => event.remove());
    
    let events = meetings.map(event => {
      let selectedColor = $(`#meeting_room_${event.meeting_room_id}`).data('meeting-room-color') || 'red';
      
      const room = rooms.find(room => room.id === event.meeting_room_id);

      return {
        title: event.business_name,
        room: room.name,
        employee: event.member?.name || 'Pembr Staff',
        start: event.start_date,
        end: event.end_date,
        image: event.image,
        textColor: "#333", 
        backgroundColor: selectedColor,
        meeting: event
      };
    });

    calendar.addEventSource(events);
  }

  function addEventCalendar(meeting, calendar) {
    let selectedColor = $(`#meeting_room_${meeting.meeting_room_id}`).data('meeting-room-color') || 'red';

    let event = {
      title: meeting.business_name,
      room: meeting.meeting_room.name,
      employee: meeting.member?.name || 'Pembr Staff',
      start: meeting.start_date,
      end: meeting.end_date,
      image: meeting.image,
      textColor: "#333",
      backgroundColor: selectedColor,
      meeting: meeting
    };

    calendar.addEvent(event);
  }


  function updateMeetingRooms(meetingRooms) {
    let html
    if (meetingRooms.length !== 0) {
      html = `
        <div class='font-semibold text-white mt-4 mb-1'>Meeting Rooms</div>
        <div class="navbar-dark py-1 rounded">
      `;
      meetingRooms.forEach(function(room, index) {
        if (index >= eventColors.length) {
          index -= eventColors.length
        }
        html += `
          <div class="my-4 d-flex align-items-center">
            <span class="py-4 rounded-end" style="width: 17px; background-color: ${eventColors[index]};"></span>
            <input type="checkbox" class="font-semibold text-white rounded cursor-pointer mx-3 form-check-label" id="meeting_room_${room.id}" data-meeting-room-id="${room.id}" data-meeting-room-color="${eventColors[index]}" checked>
            <label class="font-semibold text-white mx-0 cursor-pointer form-check-label" for="meeting_room_${room.id}">
              ${room.name}
            </label>
          </div>
        `;
      });
      html += '</div>';
    } else {
      html = '<div class="navbar-dark mt-4 rounded"></div>';
    }
    
    $('#meeting-rooms-container').html(html);
  }

  function addRoomToCalendar() {
    fetchMeetingData().then(function(response) {

      const roomCalendersContainer = document.createElement('div');
      roomCalendersContainer.id = 'rooms-container';
      roomCalendersContainer.style.display = 'flex';
      roomCalendersContainer.style.overflowX = 'scroll';

      for (var i = 0; i < response.meeting_rooms.length; i++) {
        var newRoomDiv = document.createElement('div');
        newRoomDiv.id = 'room-' + response.meeting_rooms[i].id
        roomCalendersContainer.appendChild(newRoomDiv);
        $('#calendar').append(roomCalendersContainer);
        newCalendar(response.meeting_rooms[i], i, response.meeting_rooms.length)
      }
      var url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings?meeting_room_ids=' + encodeURIComponent(response.meeting_rooms[0].id)
      fetchMeetingsData(url, false, adminCalendar)
    })
    .catch(function(error) {
      console.log(error)
    });
  }

  function removeRoomToCalendar() {
    $('[id^="room-"]').remove()
  }

  function fetchMeetingData() {
    return new Promise(function(resolve, reject) {
      buildingId = $('#schedule-building-select').val();
      url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {
          resolve(response)
        },
        error: function(error) {
          reject(error)
        }
      })
    })
  }

  function newCalendar(meeting_room, index, roomsLength) {
    var roomCalendarEl = document.getElementById('room-' + meeting_room.id);
    var url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings?meeting_room_ids=' + encodeURIComponent(meeting_room.id)

    var roomCalendar = new Calendar(roomCalendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'timeGridDay', 
      eventOverlap: false,
      headerToolbar: {
        left: '',
        center: '',
        right: ''
      },
      selectable: true,
      selectOverlap: false,
      select: function(info) {
        newMeetingModal(info, roomCalendar)
      },
      dayHeaderFormat: {weekday: 'short'},
      weekends: true,
      nowIndicator: true,
      contentHeight: 'auto',
      eventDisplay: 'block',
      dayMaxEventRows: 4,
      slotMinTime: '07:00:00',
      slotMaxTime: '20:00:00',
      displayEventTime: false,
      eventDidMount: function(info) {
        info.el.onclick = function() {

          if (document.querySelector('.customer-popover')) {
            document.querySelector('.customer-popover').remove();
          }
          
          const bodyRect = document.body.getBoundingClientRect()
          const elemRect = info.el.getBoundingClientRect()
          const offsetY   = elemRect.top - bodyRect.top;
          const offsetX   = elemRect.left - bodyRect.left;

          const popover = createMeetingPopover({
            info, 
            meetingRoomColor: info.event.backgroundColor,
            offsetX,
            offsetY,
            calendar: roomCalendar
          });

          document.body.appendChild(popover);

          // on click away from info.el
          document.body.addEventListener('click', function(e) {
            if (!info.el.contains(e.target) && !popover.contains(e.target)) {
              popover.remove();
            }
          });         

        }
      },
      eventContent: function(arg) {

        const { room, employee } = arg.event.extendedProps

        return {
          html: `<div style="padding-top: 5px">
                  <div class="mx-1">${room}</div>
                  <div class="mx-1">${arg.event.title}</div>
                  <div class="mx-1">${employee}</div>
                  <div class="mx-1">${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.start.getDay()]}</div>
                  <div class="mx-1"> to ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]}</div>
                </div>`
        }
      },
      viewDidMount: function(info) {
        roomCalendarEl.style.zIndex = 100 - index;
        roomCalendarEl.style.minWidth = "225px"
        roomCalendarEl.style.borderTopLeftRadius = "10px"
        roomCalendarEl.style.borderTopRightRadius = "10px"

        const isFirst = index === 0;
        const isLast = index === roomsLength - 1;

        let borderStyle = 'border-top-left-radius: 10px;'

        if (isFirst && isLast) {
          borderStyle = 'border-top-left-radius: 10px; border-top-right-radius: 10px;'
        } else if (isLast) {
          borderStyle = 'border-top-right-radius: 10px;'
        } else if (isFirst) {
          borderStyle = 'border-top-left-radius: 10px;'
        } else {
          borderStyle = '';
        }

        let ResourceEle = `<div class='customized-resource' style='background-color: ${eventColors[index]}; color: #333; border: 1px solid ${eventColors[index]}; ${borderStyle}'>${meeting_room.name}</div>`

        $(roomCalendarEl).prepend(ResourceEle)
      }
    });

    roomCalendar.render(); 
    fetchMeetingsData(url, false, roomCalendar)

    roomCalendars.push(roomCalendar);
  }

  function handleDatesSet(start, end ) {
    roomCalendars.forEach(function(calendar) {
      calendar.gotoDate(start);
    })
  }

  function validateMeetingForm() {
    const selectedBusiness = $('#business_select').val();
    const selectedMember = $('#member_select').val();
    const selectedMeetingRoom = $('#meeting_room_select').val();

    
    if (selectedBusiness === '0' || selectedMember === '0' || selectedMeetingRoom === '0') {
      return false
    }
    return true
  }

  
});