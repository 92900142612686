import flatpickr from "flatpickr";
import {
  datePickerMomentShortFormat,
  dateTimeOutputFormat,
  dateTimeParamsFormat,
  flatpickrShortFormat,
} from "../../calendar/utilities";
import { endDate, startDate } from "./utilities";
import getParameterByName from "./getParameterByName";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate";
import updateUrl from "./updateUrl";

export function dateTimePicker() {
  const startDateTimeInput = $("#client-datetimes");
  const endDateTimeInput = $("#endDate");
  const start = getParameterByName("start")
    ? moment(getParameterByName("start"), dateTimeParamsFormat)
    : moment(startDate);
  const end = getParameterByName("end")
    ? moment(getParameterByName("end"), dateTimeParamsFormat)
    : moment(endDate);
  const selectedBuildingId = $("#select-building-home").val();

  if (startDateTimeInput.length && endDateTimeInput.length) {
    const startDateFlatpickr = flatpickr(startDateTimeInput, {
      enableTime: true,
      dateFormat: flatpickrShortFormat,
      minDate: "today",
      minTime: "07:00",
      maxTime: "20:00",
      minuteIncrement: 15,
      defaultDate: [start.format(datePickerMomentShortFormat)],
      disableMobile: "true",
      plugins: [
        new confirmDatePlugin({
          confirmText: "Apply",
          confirmIcon: "",
        }),
      ],
      onChange: function (selectedDates, _dateStr, _instance) {
        let endDateInput = selectedDates[0];
        endDateInput.setHours(endDateInput.getHours() + 1);
        endDateFlatpickr.setDate(endDateInput);
      },
    });
    
    const endDateFlatpickr = flatpickr(endDateTimeInput, {
      enableTime: true,
      dateFormat: flatpickrShortFormat,
      minDate: "today",
      minTime: "07:00",
      maxTime: "20:00",
      minuteIncrement: 15,
      defaultDate: [end.format(datePickerMomentShortFormat)],
      disableMobile: "true",
      plugins: [
        new confirmDatePlugin({
          confirmText: "Apply",
          confirmIcon: "",
        }),
      ],
    });
    
    $(document).on("click", ".flatpickr-confirm", function (_e) {
      const startRequestDate = moment(startDateFlatpickr.selectedDates[0]).format(
        dateTimeOutputFormat
      );
      const endRequestDate = moment(endDateFlatpickr.selectedDates[0]).format(
        dateTimeOutputFormat
      );
      
      if (startRequestDate && endRequestDate && selectedBuildingId) {
        updateUrl(selectedBuildingId, startRequestDate, endRequestDate);
      }
    });
  }
}

export default dateTimePicker;
