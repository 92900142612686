$(document).ready(function() {

  $('#invitation-form').hide();


  $('#accept-invite-btn').on('click', function () {
    $('#invitation-form').show();
    $('#invitation-form').removeClass('d-none');
    $('#invitation-content').hide();
  })

})