$(document).ready(function() {
  $('#office_business_id').on('change', function() {
    var businessId = $(this).val();
    console.log(businessId);
    var url = '/api/v1/admin/businesses/' + encodeURIComponent(businessId) + '/load_lease';

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(data) {
        $('#office_lease_start_date').val(data.lease_start_date);
        $('#office_monthly_license_fee').val(data.monthly_license_fee);
        $('#office_car_space_fee').val(data.car_space_fee);
      
        var total_price = '€' + (data.monthly_license_fee + data.car_space_fee);
        if(!isNaN(data.monthly_license_fee + data.car_space_fee)) {
          $('#total-price').text(total_price);
        } else {
          $('#total-price').text('');
        }
      },
      error: function(error) {
        console.log(error)
      }
    })
    
  });

  $('#office_monthly_license_fee').on('change', function() {
    var monthly_license_fee = parseFloat($('#office_monthly_license_fee').val() || 0);
    var car_space_fee = parseFloat($('#office_car_space_fee').val() || 0);
  
    var total_price = '€' + (monthly_license_fee + car_space_fee);
    $('#total-price').text(total_price);
    
  })

  $('#office_car_space_fee').on('change', function() {
    var monthly_license_fee = parseFloat($('#office_monthly_license_fee').val() || 0);
    var car_space_fee = parseFloat($('#office_car_space_fee').val() || 0);

    var total_price = '€' + (monthly_license_fee + car_space_fee);
    $('#total-price').text(total_price);
  })

  var $statusButton = $('#button-status');
  var $valueStatus = $('#value-status');

  $statusButton.on('click', function() {
    var currentValueStatus = $valueStatus.val();
    $valueStatus.val((currentValueStatus === 'active') ? 'deactive' : 'active');
    console.log($valueStatus.val());

    if ($valueStatus.val() === 'deactive') {
      $statusButton.addClass('selected');
      $statusButton.text('Deactive')
    } else {
      $statusButton.removeClass('selected');
      $statusButton.text('Active Lease')
    }
  });
});


