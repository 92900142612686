export const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const eventColors = [
  "#FFB5A6",
  "#FFC59D",
  "#F5F5B5",
  "#CBEFE0",
  "#CBDFF3",
  "#ECD7F5",
  "#FAD0DA",
];
export const datePickerMomentFormat = "DD / MM / YYYY h:mm a";
export const datePickerMomentShortFormat = "DD / MM / h:mm a";
export const dateTimeOutputFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const dateTimeParamsFormat = "YYYY-MM-DD HH:mm:ss Z";
export const flatpickrFormat = "d / m / Y h:i K";
export const flatpickrShortFormat = "d / m / h:i K";
export const isMobile = $(window).width() < 768;
export const initialCalendarView = isMobile ? "timeGridDay" : "timeGridWeek";
export const initialHeaderToolbarRight = isMobile
  ? "today prev,next"
  : "timeGridDay,timeGridWeek,dayGridMonth today prev,next";
