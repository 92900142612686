// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import Turbolinks from "turbolinks"
import "channels"
import "./admin/meeting_room"
import "./admin/business"
import "./admin/office"
import "./admin/overview"
import "./admin/building"
import "./admin/schedule"
import "./admin/invitation"
import "./member/report"
import "./member/booking_calendar"
import "./member/meeting_room"

Rails.start()
Turbolinks.start()
Turbo.session.drive = false
ActiveStorage.start()
