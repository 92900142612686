export function createOrUpdateChart (data) {
  const trafficChartBookingReport = document.getElementById('trafficChartBookingReport');

  let chartStatus = Chart.getChart("trafficChartBookingReport");
  if (chartStatus !== undefined) {
    chartStatus.destroy();
  }
  
  new Chart(trafficChartBookingReport, {
    type: 'doughnut',
    options: {
      plugins: {
        tooltip: {
          callbacks: {
            afterLabel: function () {
              return '%';
            },
          },
        },
      },
    },
    data: {
      labels: ['Used', 'Available'],
      datasets: [
        {
          data: [data.used, data.available],
          backgroundColor: ['#F6976E', '#FDD7C7'],
        }
      ],
    },
  });
}

export default createOrUpdateChart;
