export function updateMeetingsPerRoom (meetingRooms) {
  let meetingPerRoom = $('#meetings-per-room');
  meetingPerRoom.empty();
  
  if (meetingRooms.length > 0 ) {
    meetingRooms.forEach( meetingRoom => {
      let newRow =
        '<tr>' +
        '<td><span>' +
        meetingRoom.name +
        '</span></td>' +
        '<td class="text-center text-white">' +
        meetingRoom.meetings_count +
        ' meetings </td>' +
        '</tr>'
      meetingPerRoom.append(newRow)
    })
  } else {
    const newRow = '<tr><td class="text-center text-white" colspan="2"><span> There are no Meetings </span></td></tr>'
    meetingPerRoom.append(newRow)
  }
}

export default updateMeetingsPerRoom;
