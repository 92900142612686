import apiPutRequest from "../apiPutRequest";

export function editMeeting(meetingId, formData) {
  const url =
    '/api/v1/admin/schedules/meeting/' +
    meetingId
  
  return apiPutRequest(url, formData);
}

export default editMeeting;
