import '../theme/chart';
import { formatDate } from '../helpers/dateFormatter';

$(document).ready(function() { 

  var urlParams = window.location.pathname.split('/');
  const buildingIdIndex = urlParams.indexOf('buildings') + 2;

  var buildingReportChart;
  const earningsChart = document.getElementById('earningsChart');

  var startDate = moment().startOf('month')
  var endDate = moment().endOf('month')

  
  if (buildingIdIndex > 0 && urlParams[buildingIdIndex] && urlParams[buildingIdIndex] === 'report') {
    fetchAndUpdateChart();
  }

  $('#building_meeting_room_id').on('change', function() {
    fetchAndUpdateChart();
  })

  $(function() {
    $('input[name="daterange-building"]').daterangepicker({
      startDate,
      endDate,
      opens: 'left',
      ranges: {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, function(start, end, label) {
      startDate = formatDate(start)
      endDate = formatDate(end)

      fetchAndUpdateChart();
    });
  });

  function fetchAndUpdateChart() {
    var meetingRoomId = $('#building_meeting_room_id').val()

    var url = '/api/v1/admin/buildings/'+ encodeURIComponent(urlParams[buildingIdIndex - 1]) +'/report?start_date=' + encodeURIComponent(startDate) + '&end_date=' + encodeURIComponent(endDate) + '&meeting_room_id=' + encodeURIComponent(meetingRoomId) ;

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        
        $('.total-meeting-hours').text(response.total_meeting)
        updateMeetingsPerRoomTable(response.meetings_per_room)

        if (buildingReportChart instanceof Chart) {
          buildingReportChart.data.labels = Object.keys(response.occupancy_rate_buckets)
          buildingReportChart.data.datasets[0].data = Object.values(response.occupancy_rate_buckets)
          buildingReportChart.update()
        } else {
          buildingReportChart = new Chart(earningsChart, {
            type: 'bar',
            options: {
              scales: {
                yAxisOne: {
                  display: 'auto',
                  ticks: {
                    callback: function (value) {
                      return value + '%';
                    },
                  },
                }
              },
            },
            data: {
              labels: Object.keys(response.occupancy_rate_buckets),
              datasets: [{
                data: Object.values(response.occupancy_rate_buckets),
                yAxisID: 'yAxisOne',
              }]
            }
          });
        }
      },
      error: function(error) {
        console.log(error)
      }
    })

  }


  function updateMeetingsPerRoomTable(meetingRooms) {
    $('#meetings-per-room').empty();
    if (meetingRooms.length > 0 ) {
      meetingRooms.forEach( meetingRoom => {
        var newRow = '<tr>' + '<td><span>' + meetingRoom.name + '</span></td>' + '<td class="text-center text-white">' + meetingRoom.meetings_count + ' meetings </td>' + '</tr>'
        $('#meetings-per-room').append(newRow)
      })
    } else {
      var newRow = '<tr><td class="text-center text-white" colspan="2"><span> There are no Meetings </span></td></tr>'
      $('#meetings-per-room').append(newRow)
    }
  }

})