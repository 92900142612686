import createMeeting from "../../api/meetings/createMeeting";

export function postMeeting(formData) {
  createMeeting( { meeting: formData })
    .then((_data) => {
      alert('Meeting booked successfully.');
      window.location.reload()
    })
    .catch((error) => {
      console.error('Error creating meeting:', error.responseJSON.message);
      alert(error.responseJSON.message || 'Error occurred while booking. Please try again.');
    })
}

export default postMeeting;
