export function popoverEditButton() {
  const editBtn = document.createElement('button');
  
  editBtn.id = 'delete-meeting-btn';
  editBtn.classList.add('btn', 'btn-lg', 'w-100', 'btn-sign-up', 'btn-primary', 'mt-5');
  editBtn.innerHTML = 'Edit';
  
  return editBtn;
}

export default popoverEditButton;
