import SelectBuildingListener from "./meeting_room/listeners/selectBuildingListener";
import bookingMeetingListener from "./meeting_room/listeners/bookingMeetingListener";
import innerBookingMeetingListener from "./meeting_room/listeners/innerBookingMeetingListener";
import dateTimePicker from "./meeting_room/dateTimePicker";

$(document).ready(function() {
  dateTimePicker();
  
  SelectBuildingListener();
  
  bookingMeetingListener();

  innerBookingMeetingListener();
})
