import '../theme/chart';
import { formatDate } from '../helpers/dateFormatter';
import fetchAndUpdateChart from "./reports/fetchAndUpdateChart";

const trafficChartBookingReport = document.getElementById('trafficChartBookingReport');
const meetingRoom = $('#meeting_room_id_booking_report');
const member = $('#member_id');
const dateRangePicker = $('input[name="daterange-booking-report"]')

var memberId, meetingRoomId, businessId;
var startDate = moment().startOf('month');
var endDate = moment().endOf('month');

if (trafficChartBookingReport) {
  businessId = meetingRoom.data('business-id');
  memberId = member.val() || 0;
  meetingRoomId = meetingRoom.val() || 0;
  
  fetchAndUpdateChart(businessId, memberId, meetingRoomId, startDate, endDate);
}

meetingRoom.on('change', function() {
  meetingRoomId = $(this).val() || 0;
  
  fetchAndUpdateChart(businessId, memberId, meetingRoomId, startDate, endDate);
})

member.on('change', function() {
  memberId = $(this).val() || 0;
  
  fetchAndUpdateChart(businessId, memberId, meetingRoomId, startDate, endDate);
})

if(dateRangePicker) {
  dateRangePicker.daterangepicker({
    startDate,
    endDate,
    ranges: {
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  }, function(start, end, label) {
    startDate = formatDate(start)
    endDate = formatDate(end)
    memberId = member.val() || 0
    meetingRoomId = meetingRoom.val() || 0
    
    fetchAndUpdateChart(businessId, memberId, meetingRoomId, startDate, endDate);
  });
}
