import apiGetRequest from "../apiGetRequest";

export function fetchInfoMeeting(buildingId, businessId = null) {
  let url =
    '/api/v1/admin/buildings/' +
    encodeURIComponent(buildingId) +
    '/get_info_meeting'
  if (businessId) {
    url +=
      '?business_id=' +
      encodeURIComponent(businessId)
  }
  
  return apiGetRequest(url);
}

export default fetchInfoMeeting;
