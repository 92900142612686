import createFormElement from "./createFormElement";
import createDateTimeInput from "./createDateTimeInput";
import startDateInputListener from "./listeners/startDateInputListener";

export function EditEventViewForm({
  event,
  flexDiv
}) {
  const startDateDiv = createFormElement('div', 'flex-auto building-form mt-4');
  const endDateDiv = createFormElement('div', 'flex-auto building-form mt-4');
  
  const selectedStartDay = moment(event.start)
  const selectedEndDay = moment(event.end)
  
  const meetingRoomDiv = createFormElement('div', 'flex-auto building-form');
  const meetingRoomTitle = document.createElement('h2');
  meetingRoomTitle.className = "font-16";
  meetingRoomTitle.innerHTML = event.extendedProps.room
  
  meetingRoomDiv.append(meetingRoomTitle);
  
  const startDateLable = createFormElement('lable', 'd-block', 'Start Time')
  let startDateInput = createDateTimeInput('start-date-meeting', 'start-date', '', selectedStartDay)
  const endDateLable = createFormElement('lable', 'd-block', 'End Time')
  const endDateInput = createDateTimeInput('end-date-meeting', 'end-date', '', selectedEndDay)
  
  startDateDiv.append(startDateLable, startDateInput);
  endDateDiv.append(endDateLable, endDateInput);
  flexDiv.append(meetingRoomDiv, startDateDiv, endDateDiv);
  
  startDateInputListener(startDateInput, endDateInput);
}

export default EditEventViewForm;
