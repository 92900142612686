export function popoverDeleteButton() {
  const deleteBtn = document.createElement('button');
  
  deleteBtn.id = 'delete-meeting-btn';
  deleteBtn.classList.add('btn', 'btn-lg', 'w-100', 'btn-outline-primary', 'mt-2');
  deleteBtn.innerHTML = 'Delete';
  
  return deleteBtn;
}

export default popoverDeleteButton;
