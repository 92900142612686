import '../theme/chart';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';

var urlParams = window.location.pathname.split('/');
// Convertions chart
const conversionsChart = document.getElementById('conversionsChart');

let currentView = 'timeGridWeek';
const COLOR = ["#FFB5A6", "#FFC59D", "#F5F5B5", "#CBEFE0", "#CBDFF3", "#ECD7F5", "#FAD0DA"];

const daysOfWeek = [
  'Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'
];

if (conversionsChart) {
  new Chart(conversionsChart, {
    type: 'bar',
    options: {
      scales: {
        y: {
          ticks: {
            callback: function (val) {
              return val + '%';
            },
          },
        },
      },
    },
    data: {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          label: '2020',
          data: [25, 20, 30, 22, 17, 10, 18, 26, 28, 26, 20, 32],
        },
        {
          label: '2019',
          data: [15, 10, 20, 12, 7, 0, 8, 16, 18, 16, 10, 22],
          backgroundColor: '#d2ddec',
          hidden: true,
        },
      ],
    },
  });
}

const meetingRoomEL = document.getElementById('meeting-room-calendar');

if (meetingRoomEL) {
  var url = '/api/v1/admin/meeting_rooms/' + encodeURIComponent(urlParams[5]) + '/meetings'

  var meetingRoomCalendar = new Calendar(meetingRoomEL, {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: 'timeGridWeek', 
    eventOverlap: false,
    headerToolbar: {
      left: 'title',
      center: '',
      right: 'timeGridDay,timeGridWeek,dayGridMonth today prev,next'
    },
    selectable: true,
    selectOverlap: false,
    slotMinTime: '07:00:00',
    slotMaxTime: '20:00:00',
    select: function(info) {
      newMeetingModal(info, meetingRoomCalendar)
    },
    viewClassNames: function(info) {
      currentView = info.view.type;
    },
    eventDidMount: function(info) {
      info.el.onclick = function() {

        if (document.querySelector('.customer-popover')) {
          document.querySelector('.customer-popover').remove();
        }
        
        const bodyRect = document.body.getBoundingClientRect()
        const elemRect = info.el.getBoundingClientRect()
        const offsetY   = elemRect.top - bodyRect.top;
        const offsetX   = elemRect.left - bodyRect.left;

        const popover = createMeetingPopover({
          info, 
          meetingRoomColor: info.event.backgroundColor,
          offsetX,
          offsetY,
          calendar: meetingRoomCalendar
        });

        document.body.appendChild(popover);

        // on click away from info.el
        document.body.addEventListener('click', function(e) {
          if (!info.el.contains(e.target) && !popover.contains(e.target)) {
            popover.remove();
          }
        });         

      }
    },
    eventContent: function(arg) {

      const { room, employee } = arg.event.extendedProps


      if (currentView === 'dayGridMonth') {
        return {
          html: `<div style="background-color: ${arg.event.backgroundColor}">
                  <div class="mx-1">
                  ${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} to 
                  ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]},
                  ${room},
                  ${employee},
                  ${arg.event.title}
                  </div>
                </div>`
        }
      }

      return {
        html: `<div style="padding-top: 5px">
                <div class="mx-1">${room}</div>
                <div class="mx-1">${arg.event.title}</div>
                <div class="mx-1">${employee}</div>
                <div class="mx-1">${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.start.getDay()]}</div>
                <div class="mx-1"> to ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]}</div>
              </div>`
      }
    },
    dayHeaderFormat: {weekday: 'short', day: 'numeric' },
    weekends: true,
    nowIndicator: true,
    contentHeight: 'auto',
    eventDisplay: 'block',
    dayMaxEventRows: 4,
  });

  meetingRoomCalendar.render(); 

  fetchMeetingsInMeetingRoom(url).then(function(response) {
    updateCalendarMeetingRoom(response.meetings, meetingRoomCalendar);
  })
  .catch(function(error) {
    console.log(error)
  });


}

const deleteMeeting = async (info) => {
  return fetch('/api/v1/admin/schedules/meeting/' + info.event.extendedProps.meeting.id, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  })
}

const createMeetingPopover = ({
  info, 
  meetingRoomColor,
  offsetX,
  offsetY,
  calendar
}) => {
  const popover = document.createElement('div');
  popover.innerHTML = `
    <div class="d-flex align-items: center">
      <div style="height: 15px; width: 15px; background-color: ${meetingRoomColor}; border-radius: 3px; margin-right: 5px; margin-top: 5px"></div>
      <div class="font-semibold" style="padding-top: 5px">
        <h4>
        ${info.event.extendedProps.room}
        </h4>
      </div>
    </div>
    <div class="font-semibold">${info.event.title}</div>
    <div class="font-semibold">${info.event.extendedProps.employee}</div>
    <div class="font-semibold">${info.event.start.getHours().toString().padStart(2, '0')}:${info.event.start.getMinutes().toString().padStart(2, '0')} to ${info.event.end.getHours().toString().padStart(2, '0')}:${info.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[info.event.end.getDay()]}</div>
  `

  popover.style.position = 'absolute';
  popover.style.top = offsetY + 'px';
  popover.style.left = offsetX - 260 + 'px';
  popover.style.width = '250px';
  popover.style.zIndex = 5000;
  popover.style.border = '1px solid white';
  popover.style.padding = '10px';
  popover.style.backgroundColor = '#434343';
  popover.style.borderRadius = '5px';
  popover.style.boxShadow = '7px 19px 31px 0px rgba(0,0,0,0.1)';
  popover.classList.add('customer-popover');

  const deleteBtn = document.createElement('button');
  deleteBtn.id = 'delete-meeting-btn';
  deleteBtn.classList.add('btn', 'btn-lg', 'w-100', 'btn-sign-up', 'btn-primary', 'mt-5');
  deleteBtn.innerHTML = 'Delete';
  deleteBtn.onclick = async function() {
    try {
      deleteBtn.innerHTML = 'Deleting...';
      await deleteMeeting(info)
      var url = '/api/v1/admin/meeting_rooms/' + encodeURIComponent(urlParams[5]) + '/meetings'
      fetchMeetingsInMeetingRoom(url).then(function(response) {
        updateCalendarMeetingRoom(response.meetings, calendar);
      })
      .catch(function(error) {
        console.log(error)
      });
      popover.remove();
    } catch (error) {
      console.log(error)
    }
  }
  popover.appendChild(deleteBtn);
  return popover;
}

function fetchMeetingsInMeetingRoom(url) { 
  return new Promise(function(resolve, reject) {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        resolve(response)
      },
      error: function(error) {
        reject(error)
      }
    })
  })


}



function updateCalendarMeetingRoom(meetings, calendar) {  
  calendar.getEvents().forEach(event => event.remove());
  
  let events = meetings.map(event => {
    return {
      title: event.business_name,
      room: event.meeting_room.name,
      start: event.start_date,
      end: event.end_date,
      image: event.image,
      backgroundColor: "#F6976E",
      textColor: "white",
      meeting: event,
      employee: event.member?.name || 'Pembr Staff'
    };
  });

  calendar.addEventSource(events);
}

function newMeetingModal(info, calendar) {
  var url = '/api/v1/admin/meeting_rooms/' + encodeURIComponent(urlParams[5])
  fetchMeetingRoom(url).then(function(response) {
    createMeetingForm(response, info, calendar);
  })
  .catch(function(error) {
    console.log(error)
  });  
  $('#meetingModal').modal('show')
}

function fetchMeetingRoom(url) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        resolve(response)
      },
      error: function(error) {
        reject(error)
      }
    })
  })
}

function createMeetingForm(data, info, calendar) {
  $('#form-new-meeting').empty();
  const flexDiv = createFormElement('div', 'flex');
  $('#memberModalLabel').text(data.meeting_room.building.name)

  const meetingRoomDiv = createFormElement('div', 'flex-auto building-form');
  const startDateDiv = createFormElement('div', 'flex-auto building-form mt-4');
  const endDateDiv = createFormElement('div', 'flex-auto building-form mt-4');

  var meetingRoomLable = createFormElement('lable', 'd-block modal-header', `${data.meeting_room.name}`)
  $('#modal-body-schedule').empty()
  $('#modal-body-schedule').prepend(meetingRoomLable)
  const startDateLable = createFormElement('lable', 'd-block', moment(info.start).format('dddd Do, MMMM')) 
  let startDateInput = createDateTimeInput('start-date-meeting', 'start-date', 'd-none', info.startStr) 
  let endDateInput = createDateTimeInput('end-date-meeting', 'end-date', 'd-none', info.endStr) 
  const hoursLable = createFormElement('lable', 'd-block', moment(info.start).format('h:mm A') + ' - ' + moment(info.end).format('h:mm A')) 
  startDateDiv.append(startDateLable, hoursLable, startDateInput, endDateInput);

  const businessDiv = createFormElement('div', 'flex-auto building-form mt-4');
  const businessSelect = createSelect('business_select', data.businesses, 'Company', '', 0);
  businessDiv.append(businessSelect);

  const memberDiv = createFormElement('div', 'flex-auto building-form mt-4 d-none');
  const memberSelect = createSelect('member_select', data.members, 'Employee', '', 0);
  memberDiv.append(memberSelect);


  businessSelect.on('change', function() {
    memberDiv.removeClass('d-none');
    const companyMembers = data.members.filter(member => member.business_id === parseInt(this.value));
    if (companyMembers.length === 0) {
      memberSelect.empty();
      memberDiv.addClass('d-none');
    } else {
      memberSelect.empty();
      companyMembers.forEach(function(member) {
        const option = createFormElement('option', null, member.name);
        option.val(member.id);
        memberSelect.append(option);
      });
    }
  })

  const submitDiv = createFormElement('div', 'mt-4 d-flex justify-content-end');
  const submitBtn = createFormElement('button', 'btn btn-addbuilding text-white', 'Book');
  submitBtn.attr('id', 'submitNewMeetingBtn');
  submitDiv.append(submitBtn);

  flexDiv.append(meetingRoomDiv, startDateDiv, endDateDiv, businessDiv, memberDiv);

  $('#form-new-meeting').append(flexDiv, submitDiv);

  $('#submitNewMeetingBtn').on('click', function(){
    if (validateMeetingForm()) {
      handleSubmit(calendar)
    } else {
      alert(' Please select options for "Company and Employee" ')
    }
    
  });
}

function createFormElement(elementType, className, labelText) {
  const element = $('<' + elementType + '>');
  if (className) element.addClass(className);
  if (labelText) element.text(labelText);
  return element;
}

function createDateTimeInput(id, name, className, value) {
  const dateTimeInput = createFormElement('input', 'form-control mt-2', null);
  dateTimeInput.attr({
    'type': 'datetime-local',
    'id': id,
    'name': name,
    'class': 'navbar-dark text-white rounded px-3 py-2 mt-1 w-100 ' + className,
    'value': moment(value).format('YYYY-MM-DDTHH:mm'),
  });

  return dateTimeInput;
}

function createSelect(id, options, prompt, className, defaultValue) {
  const select = $('<select>').attr('id', id).addClass('navbar-dark text-white rounded px-3 py-2 mt-1 w-100 ' + className);
  const defaultOption = createFormElement('option', null, prompt);
  defaultOption.val(defaultValue)
  select.append(defaultOption);
  $.each(options, function(_, item) {
    const option = createFormElement('option', null, item.name);
    option.val(item.id);
    select.append(option);
  });
  return select;
}

function handleSubmit(calendar) {
  const formData = {
    meeting_room_id: urlParams[5],
    business_id: $('#business_select').val(),  
    member_id:$('#member_select').val(),
    start_date: moment($('#start-date-meeting').val()).format('YYYY-MM-DDTHH:mm:ssZ'),
    end_date: moment($('#end-date-meeting').val()).format('YYYY-MM-DDTHH:mm:ssZ') 
  };    
  createMeeting(formData, calendar)
}

function createMeeting(formData, calendar) {
  $.ajax({
    url: '/api/v1/admin/schedules',
    method: 'POST',
    dataType: 'json',
    contentType: 'application/json',
    data: JSON.stringify({ meeting: formData }),
    success: function (response) {
      $('#meetingModal').modal('hide')
      addEventCalendar(response.meeting, calendar)
    },
    error: function (error) {
      console.error('Error creating meeting:', error);
      alert('Error creating meeting. Please try again.');
      $('#meetingModal').modal('hide')
    }
  });
}

function addEventCalendar(meeting, calendar) {
  let selectedColor = "white";

  let event = {
    title: meeting.business_name,
    room: meeting.meeting_room.name,
    employee: meeting.member?.name || 'Pembr Staff',
    start: meeting.start_date,
    end: meeting.end_date,
    image: meeting.image,
    textColor: selectedColor,
    backgroundColor: "#F6976E",
    meeting: meeting
  };

  calendar.addEvent(event);
}

function validateMeetingForm() {
  const selectedBusiness = $('#business_select').val();
  const selectedMember = $('#member_select').val();
  if (selectedBusiness === '0' || selectedMember === '0') {
    return false
  }
  return true
}