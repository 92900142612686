import { datePickerMomentFormat, flatpickrFormat } from "../../utilities";

export function startDateInputListener(startDateInput, endDateInput) {
  startDateInput.on('change', function() {
    const inputStartDate = moment($(this)[0].value, datePickerMomentFormat)
    
    flatpickr(endDateInput, {
      enableTime: true,
      dateFormat: flatpickrFormat,
      minDate: 'today',
      defaultDate: inputStartDate.add(1, 'hour').format(datePickerMomentFormat),
      minTime: "07:00",
      maxTime: "20:00",
      minuteIncrement: 15,
      disableMobile: "true",
    });
  })
}

export default startDateInputListener;
