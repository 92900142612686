import apiGetRequest from "../apiGetRequest";

export function fetchReports (businessId, memberId, meetingRoomId, startDate, endDate) {
  const url =
    '/api/v1/booking/reports?business_id=' +
    encodeURIComponent(businessId) +
    '&member_id=' +
    encodeURIComponent(memberId) +
    '&meeting_room_id=' +
    encodeURIComponent(meetingRoomId) +
    '&start_date=' +
    encodeURIComponent(startDate) +
    '&end_date=' +
    encodeURIComponent(endDate);
  
  return apiGetRequest(url);
}

export default fetchReports;
