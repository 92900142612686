import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import editMeeting from "../api/meetings/editMeeting";
import fetchInfoMeeting from "../api/meetings/fetchInfoMeeting";
import createPopover from "../calendar/popover/createPopover";
import popoverEditButton from "../calendar/popover/popoverEditButton";
import popoverDeleteButton from "../calendar/popover/popoverDeleteButton";
import EditEventViewForm from "../calendar/forms/EditEventViewForm";
import createFormElement from "../calendar/forms/createFormElement";
import createDateTimeInput from "../calendar/forms/createDateTimeInput";
import startDateInputListener from "../calendar/forms/listeners/startDateInputListener";
import {
  datePickerMomentFormat,
  dateTimeOutputFormat,
  daysOfWeek,
  eventColors,
  initialCalendarView,
  initialHeaderToolbarRight,
} from "../calendar/utilities";

var bookingCalendarEl = document.getElementById('booking-calendar');
var buildingId = $('#select-building-calendar').val();
var businessId = $('#business_id').val();
var url;
var bookingCalendars = []
var currentView = 'timeGridWeek'
var meetingRooms = []
const currentMemberId = $('#client-calendar').attr('data-current-member-id')

function fetchMeetingRooms(url) {
  return new Promise(function(resolve, reject) {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        const meetingRooms = response.meeting_rooms.map((room, idx) => {
          return {
            id: room.id,
            name: room.name,
            color: eventColors[idx]
          }
        })
        resolve(meetingRooms)
      },
      error: function(error) {
        console.log(error)
      }
    })
  })

}


$(document).ready(async function() {

  url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
  meetingRooms = await fetchMeetingRooms(url)

  if (bookingCalendarEl) {
    var bookingCalendar = new Calendar(bookingCalendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: initialCalendarView,
      eventOverlap: false,
      headerToolbar: {
        left: 'title',
        center: '',
        right: initialHeaderToolbarRight
      },
      selectable: true,
      selectOverlap: false,
      select: function(info) {
        newMeetingModal(info, bookingCalendar)
      },
      dayHeaderFormat: { weekday: 'short', day: 'numeric' },
      weekends: true,
      nowIndicator: true,
      contentHeight: 'auto',
      eventDisplay: 'block',
      dayMaxEventRows: 4,
      slotMinTime: '07:00:00',
      slotMaxTime: '20:00:00',
      eventDidMount: function(info) {
        info.el.onclick = function() {
          if (document.querySelector('.customer-popover')) {
            document.querySelector('.customer-popover').remove();
          }
          
          const bodyRect = document.body.getBoundingClientRect()
          const elemRect = info.el.getBoundingClientRect()
          const offsetY   = elemRect.top - bodyRect.top;
          const offsetX   = elemRect.left - bodyRect.left;
  
          const popover = createMeetingPopover({
            info, 
            meetingRoomColor: info.event.backgroundColor,
            offsetX,
            offsetY,
            calendar: bookingCalendar
          });
  
          document.body.appendChild(popover);
  
          // on click away from info.el
          document.body.addEventListener('click', function(e) {
            if (!info.el.contains(e.target) && !popover.contains(e.target)) {
              popover.remove();
            }
          });
        }
      },
      eventContent: function(arg) {
        const { room, employee } = arg.event.extendedProps
  
        if (currentView === 'dayGridMonth') {
          return {
            html: `<div style="background-color: ${arg.event.backgroundColor}">
                    <div class="mx-1">
                      ${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} to 
                      ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]},
                      ${room},
                      ${employee},
                      ${arg.event.title}
                    </div>
                  </div>`
          }
        }
  
        return {
          html: `<div style="padding-top: 5px">
                  <div class="mx-1">${room}</div>
                  <div class="mx-1">${arg.event.title}</div>
                  <div class="mx-1">${employee}</div>
                  <div class="mx-1">${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.start.getDay()]}</div>
                  <div class="mx-1"> to ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]}</div>
                </div>`
        }
      },
      viewDidMount: function(info) {
        if (info.view.type === 'timeGridDay') {
          const mainCalenderView = document.getElementById('booking-calendar').querySelector('.fc-view-harness');

          if (mainCalenderView) {
            mainCalenderView.style.display = 'none';
          }
        }
      },
      viewClassNames: function(info) {
          // alert(info.view.type)
          if (info.view.type !== currentView) {
            currentView = info.view.type;
            if (info.view.type === 'timeGridDay') {
              $('#client-calendar').addClass('day-view');
              $('#meeting-rooms-container').hide();
              if (bookingCalendars.length === 0 ) {
                addRoomToCalendar();
                
                const mainCalenderView = document.getElementById('booking-calendar').querySelector('.fc-view-harness')
                if (mainCalenderView) {
                  mainCalenderView.style.display = 'none';
                }
              }
            } else if (info.view.type === 'timeGridWeek' || info.view.type === 'dayGridMonth' ) {
              const mainCalenderView = document.getElementById('booking-calendar').querySelector('.fc-view-harness')
              if (mainCalenderView) {
                mainCalenderView.style.display = 'block';
              }
              removeRoomToCalendar();
              if (bookingCalendars.length !== 0 ) {
                var url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
                fetchMeetingsData(url, false, bookingCalendar)
              }
              bookingCalendars.splice(0, bookingCalendars.length)
              $('#client-calendar').removeClass('day-view');
              $('#meeting-rooms-container').show();
            } else {
              $('#client-calendar').removeClass('day-view');
              $('#meeting-rooms-container').show();
            }
          }
      },
      datesSet: function(info){
        handleDatesSet(info.start, info.end);
      }
    });
  
    bookingCalendar.render(); 
  
    fetchMeetingsData(url, false, bookingCalendar)
  }
  
  $('#select-building-calendar').on('change', function() {
    buildingId = $(this).val();
    url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
    fetchMeetingsData(url, false, bookingCalendar);
    if (bookingCalendar.view.type === 'timeGridDay') {
      // Trick for reload custom timeGridDay view
      bookingCalendar.changeView('timeGridWeek')
      bookingCalendar.changeView('timeGridDay');
    }
  });
  
  function newMeetingModal(info, calendar) {
    fetchInfoNewMeetingData().then(function(response) {
      createMeetingForm(response, info, calendar);
    })
    .catch(function(error) {
      console.log(error)
    });
    $('#meetingModal').modal('show')
  }
  function fetchInfoNewMeetingData() {
    return new Promise(function(resolve, reject) {
      buildingId = $('#select-building-calendar').val();
      url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/get_info_meeting?business_id=' + encodeURIComponent(businessId)
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {
          resolve(response)
        },
        error: function(error) {
          reject(error)
        }
      })
    })
  }

  function createDayViewForm({
    data, calendar, flexDiv, info
  }) {

    const meetingRoomDiv = createFormElement('div', 'flex-auto building-form d-none');
    const startDateDiv = createFormElement('div', 'flex-auto building-form');
    const endDateDiv = createFormElement('div', 'flex-auto building-form mt-4');

    var findMeetingRoom 
    if (calendar.el.id === "booking-calendar") {
      findMeetingRoom = data.meeting_rooms[0]
    } else {
      findMeetingRoom = data.meeting_rooms.find(function (obj) {
        return obj.id === parseInt(calendar.el.id.replace("booking-room-", ""), 10);
      })
    }

    const meetingRoomLable = createFormElement('lable', 'd-block modal-header', `${(findMeetingRoom && findMeetingRoom.name)}` || "") 
    const meetingRoomSelect = createSelect('meeting_room_select', data.meeting_rooms, 'Room', 'd-none', (findMeetingRoom && findMeetingRoom.id) || "");
    meetingRoomDiv.append(meetingRoomSelect);
   
    $('#modal-body-schedule').empty()
    $('#modal-body-schedule').prepend(meetingRoomLable)

    const startDateInput = createDateTimeInput('start-date-meeting', 'start-date', '', info.startStr) 
    const startDateLable = createFormElement('lable', null, 'Start date') 
    startDateDiv.append(startDateLable, startDateInput);

    const endDateInput = createDateTimeInput('end-date-meeting', 'end-date', '', info.endStr) 
    const endDateLable = createFormElement('lable', null, 'End date') 
    endDateDiv.append(endDateLable, endDateInput);

    flexDiv.append(meetingRoomDiv, startDateDiv, endDateDiv);
    
    startDateInputListener(startDateInput, endDateInput);
  }

  function createWeekViewForm({
    data, calendar, info, flexDiv
  }) {
    $('#modal-body-schedule').empty()

    const startDateDiv = createFormElement('div', 'flex-auto building-form mt-4');
    const endDateDiv = createFormElement('div', 'flex-auto building-form mt-4');

    const meetingRoomDiv = createFormElement('div', 'flex-auto building-form');
    const meetingRoomSelect = createSelect('meeting_room_select', data.meeting_rooms, 'Room', 'mt-0', 0);
    const meetingRoomLable = createFormElement('lable', 'd-block', 'Meeting Room')
    meetingRoomDiv.append(meetingRoomLable, meetingRoomSelect);

    const startDateLable = createFormElement('lable', 'd-block', moment(info.start).format('dddd Do, MMMM'))
    let startDateInput = createDateTimeInput('start-date-meeting', 'start-date', 'd-none', info.startStr) 
    const endDateInput = createDateTimeInput('end-date-meeting', 'end-date', 'd-none', info.endStr) 
    const hoursLable = createFormElement('lable', 'd-block', moment(info.start).format('h:mm A') + ' - ' + moment(info.end).format('h:mm A')) 
    
    startDateDiv.append(startDateLable, hoursLable, startDateInput, endDateInput);
    flexDiv.append(meetingRoomDiv, startDateDiv, endDateDiv);
    
    startDateInputListener(startDateInput, endDateInput);
  }

  function createMonthViewForm({ data, calendar, info, flexDiv }) {
    const startDateDiv = createFormElement('div', 'flex-auto building-form mt-4');
    const endDateDiv = createFormElement('div', 'flex-auto building-form mt-4');

    // Clear title of modal
    $('#modal-body-schedule').empty()

    const currentDate = moment()
    const selectedDay = moment(info.start)
    const startDayAndTime = selectedDay.clone().hours(currentDate.hours()).minutes(0)
    const endDayAndTime = startDayAndTime.clone().add(1, 'hour')
 
    const meetingRoomDiv = createFormElement('div', 'flex-auto building-form');
    const meetingRoomLable = createFormElement('lable', '', 'Meeting Room')
    const meetingRoomSelect = createSelect('meeting_room_select', data.meeting_rooms, 'Room', '', 0);

    meetingRoomDiv.append(meetingRoomLable, meetingRoomSelect);

    const startDateLable = createFormElement('lable', 'd-block', 'Start Time')
    let startDateInput = createDateTimeInput('start-date-meeting', 'start-date', '', startDayAndTime) 
    const endDateLable = createFormElement('lable', 'd-block', 'End Time')
    const endDateInput = createDateTimeInput('end-date-meeting', 'end-date', '', endDayAndTime) 
    // const hoursLable = createFormElement('lable', 'd-block', moment(info.start).format('h:mm A') + ' - ' + moment(info.end).format('h:mm A')) 
    
    
    startDateDiv.append(startDateLable, startDateInput);
    endDateDiv.append(endDateLable, endDateInput);
    flexDiv.append(meetingRoomDiv, startDateDiv, endDateDiv);
    
    startDateInputListener(startDateInput, endDateInput);
  }
  
  function createMeetingForm(data, info, calendar) {

    const isDayView = info.view.type === "timeGridDay";
    const isWeekView = info.view.type === "timeGridWeek";

    $('#form-new-meeting').empty();
    $('#memberModalLabel').text(data.building.name)

    const flexDiv = createFormElement('div', 'flex');  
  
    if (isDayView) {
      createDayViewForm({ data, info, calendar, flexDiv })
    } else if (isWeekView) {
      createWeekViewForm({ data, info, calendar, flexDiv })
    } else {
      createMonthViewForm({ data, info, calendar, flexDiv })
    }

    const businessDiv = createFormElement('div', 'flex-auto building-form mt-4');
    const businessSelect = createSelect('business_select', data.businesses, 'Company', 'd-none', $('#business_id').val());
    businessDiv.append(businessSelect);
  
    var findMember = data.members.find(function (obj) {
      return obj.id === parseInt($('#member_id').val());
    })
  
    const memberDiv = createFormElement('div', 'flex-auto building-form mt-4');
    const memberSelect = createSelect('member_select', data.members, 'Employee', 'd-none', findMember && findMember.id || 0);
  
    const submitDiv = createFormElement('div', 'mt-4 d-flex justify-content-end');
    const submitBtn = createFormElement('button', 'btn btn-lg w-100 btn-sign-up btn-primary "', 'Book');
    submitBtn.attr('id', 'submitNewMeetingBtn');
    submitDiv.append(submitBtn);

  
    $('#form-new-meeting').append(flexDiv, submitDiv, businessDiv, memberDiv);
  
    $('#submitNewMeetingBtn').on('click', function(){
      $(this).html('Booking...');
      $(this).prop('disabled', true);
      if (validateMeetingForm()) {
        handleSubmit(calendar)
      } else {
        $(this).html('Book');
        $(this).prop('disabled', false);
        alert(' Please select all options ')
      }
    });
  }
  
  function createSelect(id, options, prompt, className, defaultValue) {
    const select = $('<select>').attr('id', id).addClass('form-control rounded px-3 py-2 mt-1 w-100 ' + className);
    const defaultOption = createFormElement('option', null, prompt);
    defaultOption.val(defaultValue)
    select.append(defaultOption);
    $.each(options, function(_, item) {
      const option = createFormElement('option', null, item.name);
      option.val(item.id);
      select.append(option);
    });
    return select;
  }
  
  function handleDatesSet(start, end ) {
    bookingCalendars.forEach(function(calendar) {
      calendar.gotoDate(start);
    })
  }
  
  function addRoomToCalendar() {
    fetchMeetingData().then(function(response) {
  
      const roomCalendersContainer = document.createElement('div');
      roomCalendersContainer.id = 'rooms-container';
      roomCalendersContainer.style.display = 'flex';
      roomCalendersContainer.style.overflowX = 'scroll';
  
      for (var i = 0; i < response.meeting_rooms.length; i++) {
        var newRoomDiv = document.createElement('div');
        newRoomDiv.id = 'booking-room-' + response.meeting_rooms[i].id
        roomCalendersContainer.appendChild(newRoomDiv);
        $('#client-calendar').append(roomCalendersContainer);
        newCalendar(response.meeting_rooms[i], i, response.meeting_rooms.length)
      }
      var url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings?meeting_room_ids=' + encodeURIComponent(response.meeting_rooms[0].id)
      fetchMeetingsData(url, false, bookingCalendar)
    })
    .catch(function(error) {
      console.log(error)
    });
  }
  
  function removeRoomToCalendar() {
      $('[id^="room-"]').remove()
      $('#rooms-container').remove()
  }
  
  function fetchMeetingData() {
    return new Promise(function(resolve, reject) {
      buildingId = $('#select-building-calendar').val();
      url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(response) {
          resolve(response)
        },
        error: function(error) {
          reject(error)
        }
      })
    })
  }

  const deleteMeeting = async (info) => {
    return fetch('/api/v1/admin/schedules/meeting/' + info.event.extendedProps.meeting.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
  }
  
  function editMeetingData(meetingId, formData, calendar) {
    url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
    
    editMeeting(meetingId, { meeting: formData })
      .then((_data) => {
        $('#meetingModal').modal('hide')
        fetchMeetingsData(url, false, calendar)
      })
      .catch((error) => {
        console.error('Error creating meeting:', error);
        alert('Error creating meeting. Please try again.');
        $('#meetingModal').modal('hide')
      })
  }
  
  function editMeetingModal(event, calendar) {
    fetchInfoMeeting(buildingId, businessId)
      .then((data) => {
        editMeetingForm(data.meeting_rooms, event, calendar);
      })
      .catch((error) => {
        console.log(error)
      });
    $('#meetingModal').modal('show')
  }
  
  function editMeetingForm(meeting_rooms, event, calendar) {
    const editForm = $('#form-new-meeting');
    editForm.empty();
    $('#memberModalLabel').text(event.extendedProps.meeting.building_name)
    
    const flexDiv = createFormElement('div', 'flex');
    
    EditEventViewForm({
      event,
      flexDiv,
    })
    
    const submitDiv = createFormElement('div', 'mt-4 d-flex justify-content-end');
    const submitBtn = createFormElement('button', 'btn btn-lg w-100 btn-sign-up btn-primary "', 'Edit');
    submitBtn.attr('id', 'submitEditMeetingBtn');
    submitDiv.append(submitBtn);
    
    editForm.append(flexDiv, submitDiv);
    
    $('#submitEditMeetingBtn').on('click', function(){
      $(this).html('Editing...');
      $(this).prop('disabled', true);
      if (validateMeetingForm()) {
        handleEditSubmit(event.extendedProps.meeting.id, calendar)
      } else {
        alert(' Please select all options ')
      }
    });
  }
  
  function handleEditSubmit(meetingId, calendar) {
    const formData = {
      start_date: moment($('#start-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat),
      end_date: moment($('#end-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat)
    }
    
    editMeetingData(meetingId, formData, calendar)
  }
  
  const createMeetingPopover = ({
    info, 
    meetingRoomColor,
    offsetX,
    offsetY,
    calendar
  }) => {
    const popover = createPopover({
      info,
      meetingRoomColor,
      offsetY,
      offsetX
    });
    
    const editable = info.event.start.getTime() > Date.now() && info.event.end.getTime() > Date.now()
    
    if (info.event.extendedProps.isDeleted && editable) {
      const editBtn = popoverEditButton();
      editBtn.onclick = function() {
        popover.remove();
        
        editMeetingModal(info.event, calendar);
      }
      
      const deleteBtn = popoverDeleteButton();
      deleteBtn.onclick = async function() {
        try {
          deleteBtn.innerHTML = 'Deleting...';
          await deleteMeeting(info)
          
          if (calendar.el.id === 'booking-calendar') {
            fetchMeetingsData(
              `/api/v1/admin/buildings/${encodeURIComponent(buildingId)}/meetings`,
              false,
              calendar
            )
          } else {
            fetchMeetingsForRoom(
              `/api/v1/admin/buildings/${encodeURIComponent(buildingId)}/meetings?meeting_room_ids=${encodeURIComponent(info.event.extendedProps.meeting.meeting_room_id)}`,
              calendar
            )
          }
          
          popover.remove();
        } catch (error) {
          console.log(error)
        }
      }
      popover.appendChild(editBtn);
      popover.appendChild(deleteBtn);
    }
    
    return popover;
  }
  
  function newCalendar(meeting_room, index, roomsLength) {
    var bookingRoomCalendarEl = document.getElementById('booking-room-' + meeting_room.id);
    var url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings?meeting_room_ids=' + encodeURIComponent(meeting_room.id)
  
    var bookingRoomCalendar = new Calendar(bookingRoomCalendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'timeGridDay',
      eventOverlap: false,
      headerToolbar: {
        left: '',
        center: '',
        right: ''
      },
      selectable: true,
      selectOverlap: false,
      select: function(info) {
        newMeetingModal(info, bookingRoomCalendar)
      },
      dayHeaderFormat: {weekday: 'short'},
      weekends: true,
      nowIndicator: true,
      contentHeight: 'auto',
      eventDisplay: 'block',
      dayMaxEventRows: 4,
      slotMinTime: '07:00:00',
      slotMaxTime: '20:00:00',
      displayEventTime: false,
      eventDidMount: function(info) {
        info.el.onclick = function() {
  
          if (document.querySelector('.customer-popover')) {
            document.querySelector('.customer-popover').remove();
          }
          
          const bodyRect = document.body.getBoundingClientRect()
          const elemRect = info.el.getBoundingClientRect()
          const offsetY   = elemRect.top - bodyRect.top;
          const offsetX   = elemRect.left - bodyRect.left;
  
          const popover = createMeetingPopover({
            info, 
            meetingRoomColor: info.event.backgroundColor,
            offsetX,
            offsetY,
            calendar: bookingRoomCalendar
          });
  
          document.body.appendChild(popover);
  
          // on click away from info.el
          document.body.addEventListener('click', function(e) {
            if (!info.el.contains(e.target) && !popover.contains(e.target)) {
              popover.remove();
            }
          });         
  
        }
      },
      eventContent: function(arg) {
        if (currentView === 'dayGridMonth') {
          return {
            html: `<div style="background-color: ${arg.event.backgroundColor}">
                    <div class="mx-1">
                      ${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} to
                      ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} 
                      ${daysOfWeek[arg.event.start.getDay()]}
                    </div>
                  </div>`
          }
        }
        const { room, employee } = arg.event.extendedProps
        return {
          html: `<div style="padding-top: 5px">
                  <div class="mx-1">${room}</div>
                  <div class="mx-1">${arg.event.title}</div>
                  <div class="mx-1">${employee}</div>
                  <div class="mx-1">${arg.event.start.getHours().toString().padStart(2, '0')}:${arg.event.start.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.start.getDay()]}</div>
                  <div class="mx-1"> to ${arg.event.end.getHours().toString().padStart(2, '0')}:${arg.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[arg.event.end.getDay()]}</div>
                </div>`
        }
      },
      viewDidMount: function(info) {
        bookingRoomCalendarEl.style.zIndex = 100 - index;
        bookingRoomCalendarEl.style.minWidth = "225px"
        bookingRoomCalendarEl.style.borderTopLeftRadius = "10px"
        bookingRoomCalendarEl.style.borderTopRightRadius = "10px"
  
        const isFirst = index === 0;
        const isLast = index === roomsLength - 1;
  
        let borderStyle = 'border-top-left-radius: 10px;'
  
        if (isFirst && isLast) {
          borderStyle = 'border-top-left-radius: 10px; border-top-right-radius: 10px;'
        } else if (isLast) {
          borderStyle = 'border-top-right-radius: 10px;'
        } else if (isFirst) {
          borderStyle = 'border-top-left-radius: 10px;'
        } else {
          borderStyle = '';
        }
  
        let ResourceEle = `
          <div 
            class='customized-resource'
            style='background-color: ${eventColors[index]}; color: #333; border: 1px solid ${eventColors[index]}; ${borderStyle}'
          >
            ${meeting_room.name}
          </div>
        `
  
        $(bookingRoomCalendarEl).prepend(ResourceEle)
      }
    });
  
    bookingRoomCalendar.render(); 
    fetchMeetingsData(url, false, bookingRoomCalendar)
    bookingCalendars.push(bookingRoomCalendar);
  
  }

  function fetchMeetingsForRoom(url, calendar) {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        updateCalendar(response.meetings, calendar)
      },
      error: function(error) {
        console.log(error)
      }
    })
  }
  
  function fetchMeetingsData(url, isMeetingRoom, calendar) { 
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        updateCalendar(response.meetings, calendar)
      },
      error: function(error) {
        console.log(error)
      }
    })
  }
  
  
  async function updateCalendar(meetings, calendar) {
    url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
    meetingRooms = await fetchMeetingRooms(url)
    
    calendar.getEvents().forEach(event => event.remove());
    
    let events = meetings.map(event => {
      const room = meetingRooms.find(room => room.id === event.meeting_room_id);
      const isDeleted = event.business_members.includes(parseInt(currentMemberId));
      return {
        id: event.id,
        title: event.business_name,
        room: room.name,
        employee: event.member?.name || 'Pembr Staff',
        start: event.start_date,
        end: event.end_date,
        image: event.image,
        textColor: "#333",
        backgroundColor: room.color,
        meeting: event,
        isDeleted: isDeleted,
        building_name: event.building_name
      };
    });
    
    calendar.addEventSource(events);
  }
  
  function handleSubmit(calendar) {
    const formData = {
      meeting_room_id: $('#meeting_room_select').val(),
      business_id: $('#business_select').val(),  
      // member_id:$('#member_select').val(),
      start_date: moment($('#start-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat),
      end_date: moment($('#end-date-meeting').val(), datePickerMomentFormat).format(dateTimeOutputFormat)
    };    
    createMeeting(formData, calendar)
  }
  
  function createMeeting(formData, calendar) {
    $.ajax({
      url: '/api/v1/admin/schedules',
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({ meeting: formData }),
      success: function (response) {
        $('#meetingModal').modal('hide')
        addEventCalendar(response.meeting, calendar)
      },
      error: function (error) {
        console.error('Error creating meeting: ', error);
        alert(`Error creating meeting: ${error.responseJSON.errors}`);
        $('#meetingModal').modal('hide')
      }
    });
  }
  
  function validateMeetingForm() {
    const selectedBusiness = $('#business_select').val();
    const selectedMember = $('#member_select').val();
    const selectedMeetingRoom = $('#meeting_room_select').val();
  
    
    if (selectedBusiness === '0' || selectedMember === '0' || selectedMeetingRoom === '0') {
      return false
    }
    return true
  }
  
  async function addEventCalendar(meeting, calendar) {
    url = '/api/v1/admin/buildings/' + encodeURIComponent(buildingId) + '/meetings'
    meetingRooms = await fetchMeetingRooms(url)
    
    const selectedColor = meetingRooms.find(room => room.id === meeting.meeting_room_id).color;
    const isDeleted = meeting.business_members.includes(parseInt(currentMemberId));
    
    let event = {
      title: meeting.business_name,
      room: meeting.meeting_room.name,
      employee: meeting.member?.name || 'Pembr Staff',
      start: meeting.start_date,
      end: meeting.end_date,
      image: meeting.image,
      textColor: "#333",
      backgroundColor: selectedColor,
      meeting: meeting,
      building_name: meeting.building_name,
      isDeleted: isDeleted,
    };
    
    calendar.addEvent(event);
  }

})

