import { daysOfWeek, isMobile } from "../utilities";

export function createPopover({
  info,
  meetingRoomColor,
  offsetY,
  offsetX
}) {
  const popover = document.createElement('div');
  
  popover.innerHTML = `
    <div class="d-flex align-items: center">
      <div style="height: 15px; width: 15px; background-color: ${meetingRoomColor}; border-radius: 3px; margin-right: 5px; margin-top: 5px"></div>
      <div class="font-semibold" style="padding-top: 5px">
        <h4>
          ${info.event.extendedProps.room}
        </h4>
      </div>
    </div>
    <div class="font-semibold">${info.event.title}</div>
    <div class="font-semibold">${info.event.extendedProps.employee}</div>
    <div class="font-semibold">
      ${info.event.start.getHours().toString().padStart(2, '0')}:${info.event.start.getMinutes().toString().padStart(2, '0')} to ${info.event.end.getHours().toString().padStart(2, '0')}:${info.event.end.getMinutes().toString().padStart(2, '0')} ${daysOfWeek[info.event.end.getDay()]}
    </div>    `
  
  const leftOffset = isMobile ? offsetX - 20 : offsetX - 260
  
  popover.style.position = 'absolute';
  popover.style.top = offsetY + 'px';
  popover.style.left =  leftOffset + 'px';
  popover.style.width = '250px';
  popover.style.zIndex = 5000;
  popover.style.border = '1px solid white';
  popover.style.padding = '10px';
  popover.style.backgroundColor = '#434343';
  popover.style.borderRadius = '5px';
  popover.style.boxShadow = '7px 19px 31px 0px rgba(0,0,0,0.1)';
  popover.classList.add('customer-popover');
  
  return popover;
}

export default createPopover;
