import { dateTimeOutputFormat } from "../../calendar/utilities";
export const startDate = moment()
  .startOf("hour")
  .add(1, "hour")
  .format(dateTimeOutputFormat);
export const endDate = moment()
  .startOf("hour")
  .add(2, "hour")
  .format(dateTimeOutputFormat);
export const confirmText = "Are you sure you want to book this meeting room?";
