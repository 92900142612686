export function updateMeetings(data) {
  let meeting = $('#meetings');
  meeting.empty();
  
  if (data.length > 0 ) {
    data.forEach( meetingRoom => {
      let newRow =
        '<tr>' +
        '<td><span>' +
        meetingRoom.name +
        '</span></td>' +
        '<td class="text-center text-white">' +
        meetingRoom.date +
        '</td>' +
        '<td class="text-center text-white">' +
        meetingRoom.start_date +
        '</td>' +
        '<td class="text-center text-white">' +
        meetingRoom.end_date +
        '</td>' +
        '</tr>'
      meeting.append(newRow)
    })
  } else {
    const newRow = '<tr><td class="text-center text-white" colspan="2"><span> There are no Meetings </span></td></tr>'
    meeting.append(newRow)
  }
}

export default updateMeetings;
