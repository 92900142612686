import flatpickr from "flatpickr";
import createFormElement from "./createFormElement";
import { datePickerMomentFormat, flatpickrFormat } from "../utilities";

export function createDateTimeInput(id, name, className, value) {
  const dateTimeInput = createFormElement('input', 'form-control mt-2', null);
  dateTimeInput.addClass( "flatpickr form-control rounded px-3 py-2 mt-1 w-100 " + className);
  dateTimeInput.attr('id', id);
  dateTimeInput.attr('name', name);
  dateTimeInput.attr('value', moment(value).format(datePickerMomentFormat));
  
  flatpickr(dateTimeInput, {
    enableTime: true,
    dateFormat: flatpickrFormat,
    minDate: 'today',
    minTime: "07:00",
    maxTime: "20:00",
    minuteIncrement: 15,
    disableMobile: "true"
  });
  
  return dateTimeInput;
}

export default createDateTimeInput;
