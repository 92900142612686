import '../theme/chart';
import { formatDate } from '../helpers/dateFormatter';
import { toggleLegendAfterChart } from '../theme/chart';

$(document).ready(function() {
  const legends = document.querySelectorAll('[data-toggle="legend"]');

  const trafficChartLease = document.getElementById('trafficChartLease');

  var dashboardChart;

  var meetingRoomOccupancyChart;
  const meetingRoomOccupancyChartElement = document.getElementById('meetingRoomOccupancyChart');

  var isRunLegend = true;

  var urlParams = window.location.pathname.split('/');

  var startDate = moment().startOf('month')
  var endDate = moment().endOf('month')

  if (urlParams.length == 2 && urlParams[1] === 'admin') {
    var url = `/api/v1/admin/overview?start_date=${startDate}&end_date=${endDate}`
    fetchAndUpdateChart(url);
  }

  $('#building_id').on('change', function() {
    var buildingId = $(this).val() || 0;
    var url = `/api/v1/admin/overview?&start_date=${startDate}&end_date=${endDate}`
    if (buildingId) url += `&building_id=${buildingId}`
    fetchAndUpdateChart(url);
  })

  $(function() {
    $('input[name="daterange-dashboard"]').daterangepicker({
      opens: 'left',
      startDate,
      endDate,
      ranges: {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, function(start, end, label) {
      startDate = formatDate(start)
      endDate = formatDate(end)
      var buildingId = $('#building_id').val()
      var url = `/api/v1/admin/overview?&start_date=${startDate}&end_date=${endDate}`
      if (buildingId) url += `&building_id=${buildingId}`
      fetchAndUpdateChart(url);
    });
  });

  function fetchAndUpdateChart(url) { 
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {

        createOrUpdateChart([response.occupancy_rate, response.vacancy_rate])
        updateTotalMeeting(response.meetings_count)
        updateOccupancyRate(response.occupancy_rate)
        updateMeetingRoomOccupancyChart(response.meeting_room_occupancy)
        updateDashboardBusiness(response.businesses)
        updateDashboardMeeting(response.meetings_per_room)
        
        if (isRunLegend) {
          toggleLegendAfterChart(legends);
          isRunLegend = false;
        }
      },
      error: function(error) {
        console.log(error)
      }
    })
  }

  function updateTotalMeeting(total_meeting) {
    $('.total-meeting-hours').text(total_meeting)
  }

  function updateOccupancyRate(occupancy_rate) {
    $('#occupancy').text(occupancy_rate + '%')
    $('#occupancy-progress-bar').css('width', occupancy_rate + '%')
  }

  function updateMeetingRoomOccupancyChart(data) {
    if (meetingRoomOccupancyChart instanceof Chart) {
      meetingRoomOccupancyChart.data.labels = Object.keys(data)
      meetingRoomOccupancyChart.data.datasets[0].data = Object.values(data)
      meetingRoomOccupancyChart.update()
    } else {
      meetingRoomOccupancyChart = new Chart(meetingRoomOccupancyChartElement, {
        type: 'bar',
        options: {
          scales: {
            yAxisOne: {
              display: 'auto',
              ticks: {
                callback: function (value) {
                  return value + '%';
                },
              },
            }
          },
        },
        data: {
          labels: Object.keys(data),
          datasets: [{
            data: Object.values(data),
            yAxisID: 'yAxisOne',
          }]
        }
      });
    }
  }

  function createOrUpdateChart (data) {
    if (dashboardChart instanceof Chart) {
      // TODO: Will remove comments when we have data returned from the api or third party
      dashboardChart.data.datasets[0].data = data
      dashboardChart.update()
    } else {
      dashboardChart = new Chart(trafficChartLease, {
        type: 'doughnut',
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                afterLabel: function () {
                  return '%';
                },
              },
            },
          },
        },
        data: {
          labels: ['Leased', 'Available'],
          datasets: [
            {
              data: data,
              backgroundColor: ['#F6976E', '#FDD7C7'],
            }
          ],
        },
      });
    }

  }

  function updateDashboardMeeting(meetingRooms) {    
    $('.dashboard-meeting-rooms tbody').empty();
    if (meetingRooms.length > 0 ) {
      meetingRooms.forEach( meetingRoom => { 
        console.log({ meetingRoom })
        var newRow = '<tr>' + '<td><span>' + meetingRoom.name + '</span></td>' + '<td class="text-center text-white">' + meetingRoom.meetings_count + ' meetings </td>' + '</tr>'
        $('.dashboard-meeting-rooms tbody').append(newRow)
      })
    } else {
      var newRow = '<tr><td class="text-center text-white" colspan="2"><span> There are no Meetings </span></td></tr>'

        $('.dashboard-meeting-rooms tbody').append(newRow)
    }
  }

  function updateDashboardBusiness(businesses) {
    $('.dashboard-businesses tbody').empty();
    if (businesses.length > 0 ) {
      businesses.forEach( business => { 
        var newRow = '<tr>' + '<td><span>' + business.name + '</span></td>' + '<td class="text-center text-white">' + business.used + '</td>' + '</tr>'

        $('.dashboard-businesses tbody').append(newRow)
      })
    } else {
      var newRow = '<tr><td class="text-center text-white" colspan="2"><span> There are no Businesses </span></td></tr>'

        $('.dashboard-businesses tbody').append(newRow)
    }
  }

})