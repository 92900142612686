import updateUrl from "../updateUrl";

export function SelectBuildingListener() {
  $(document).on('change', '#select-building-home', function() {
    const selectedBuildingId = $(this).val();
    
    updateUrl(selectedBuildingId);
  })
}

export default SelectBuildingListener;
