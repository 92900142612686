import { dateTimeParamsFormat } from "../../../calendar/utilities";
import getParameterByName from "../getParameterByName";
import postMeeting from "../postMeeting";
import { confirmText } from "../utilities";

export function innerBookingMeetingListener() {
  $(document).on("click", "#btn-client-booking-detail", function (e) {
    e.preventDefault();

    const confirmed = confirm(confirmText);

    if (confirmed) {
      const formData = {
        meeting_room_id: $(this).data("meeting-room-id"),
        business_id: $("#meeting_room_business_id").val(),
        start_date: moment(getParameterByName("start"), dateTimeParamsFormat),
        end_date: moment(getParameterByName("end"), dateTimeParamsFormat),
      };

      postMeeting(formData);
    }
  });
}

export default innerBookingMeetingListener;
