import { endDate, startDate } from "./utilities";

export function updateUrl(selectedBuildingId, start, end) {
  window.location.href = 'homes?building_id=' +
    encodeURIComponent(selectedBuildingId) +
    '&start=' +
    encodeURIComponent(start || startDate) +
    '&end=' +
    encodeURIComponent(end || endDate)
}

export default updateUrl;
