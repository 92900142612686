import fetchReports from "../../api/reports/fetchReports";
import updateMeetingsPerRoom from "./updateMeetingsPerRoom";
import updateMeetings from "./updateMeetings";
import { toggleLegendAfterChart } from "../../theme/chart";
import createOrUpdateChart from "./createOrUpdateChart";

export function fetchAndUpdateChart(businessId, memberId, meetingRoomId, startDate, endDate) {
  const legends = document.querySelectorAll('[data-toggle="legend"]');
  const chartLegendItem = $('.chart-legend-item');
  
  fetchReports(businessId, memberId, meetingRoomId, startDate, endDate)
    .then((data) => {
      console.log(data);
      createOrUpdateChart(data)
      updateMeetingsPerRoom(data.meetings_per_room)
      updateMeetings(data.meetings)
      if (chartLegendItem.length === 0) {
        toggleLegendAfterChart(legends);
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

export default fetchAndUpdateChart;
