import { dateTimeParamsFormat } from "../../../calendar/utilities";
import getParameterByName from "../getParameterByName";
import postMeeting from "../postMeeting";
import { confirmText, endDate, startDate } from "../utilities";

export function bookingMeetingListener() {
  $(document).on("click", ".client-booking-meeting", function (e) {
    e.preventDefault();

    const confirmed = confirm(confirmText);

    if (confirmed) {
      const formData = {
        meeting_room_id: $(this).data("meeting-room-id"),
        business_id: $("#business_id").val(),
        start_date: getParameterByName("start")
          ? moment(getParameterByName("start"), dateTimeParamsFormat)
          : startDate,
        end_date: getParameterByName("end")
          ? moment(getParameterByName("end"), dateTimeParamsFormat)
          : endDate,
      };

      postMeeting(formData);
    }
  });
}

export default bookingMeetingListener;
