import '../theme/chart';
import { formatDate } from '../helpers/dateFormatter';
import { toggleLegendAfterChart } from '../theme/chart';

$(document).ready(function() {

  const legends = document.querySelectorAll('[data-toggle="legend"]');
  var isRunLegend = true;

  $('#member_search_input').on('change', function() {
    var searchTerm = $(this).val();
    var businessId = $(this).data("business-id")
    var addMemberToBusiness = $(this).data("add-member-to-business")
    if (addMemberToBusiness === 'true') {
      var url = '/api/v1/admin/members?q[name_cont]=' + encodeURIComponent(searchTerm) + '&business_id=' + encodeURIComponent(businessId);
    } else {
      var buildingId = $(this).data('building-id')
      var url = '/api/v1/admin/members?q[name_cont]=' + encodeURIComponent(searchTerm) + '&building_id=' + encodeURIComponent(buildingId)  + '&business_id=' + encodeURIComponent(businessId);
    }
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        console.log(response.members)
        if (addMemberToBusiness === 'true') {
          updateMembersList(response.members, true)
        } else {
          updateMembersList(response.members, false)
        }
      },
      error: function(error) {
        console.log(error)
      }
    })
    
  })

  $('.open-member-modal').on('click', function() {
    var buildingId = $(this).data('building-id')
    var businessId = $(this).data('business-id')
    var url = '/api/v1/admin/members?building_id=' + encodeURIComponent(buildingId) + '&business_id=' + encodeURIComponent(businessId);

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {
        console.log(response.members)
        updateMembersList(response.members, false)
        $('#memberModal').modal('show')
      },
      error: function(error) {
        console.log(error)
      }
    })
    
  })

  $('#close-button-member').on('click', function () {
    location.reload();
  })

  function updateMembersList(members, addMemberToBusiness) {
    $(".member-row").remove();
    var businessId = $('#member_search_input').data("business-id")
    var buildingId = $('#member_search_input').data("building-id")

    if (addMemberToBusiness) {
      if (members && members.length > 0) {
        members.forEach( member => {
          var newRow = $("<div class='row align-items-center mt-2 member-row' data-member-name='" + member.name.toLowerCase() + "'>" +
                          "<div class='col-9'>" + member.name + "</div>" +
                          "<div class='col-3'>" +
                            "<a href='/admin/buildings/" + buildingId + "/businesses/" + businessId + "/add_member_to_business?member_id=" + member.id + "' data-method='post' data-remote='true' id='add_member_button_" + member.id + "' class='btn btn-addbuilding add-member-btn w-100 " + (member.access_member_business ? 'd-none' : '') + "'>Add</a>" +
                            "<a href='/admin/buildings/" + buildingId + "/businesses/" + businessId + "/remove_member_from_business?member_id=" + member.id + "' data-method='delete' data-remote='true' id='remove_member_button_" + member.id + "' class='btn bg-danger add-member-btn w-100 " + (member.access_member_business ? '' : 'd-none') + "'>Remove</a>" +
                          "</div>" +
                        "</div>");
      
          $("#members-list").append(newRow);
        });
      } else {
        var noMembersRow = $("<div class='row align-items-center mt-2 member-row'>" +
                             "<div class='col-12 text-center'> There are no Members </div>" +
                             "</div>");
    
        $("#members-list").append(noMembersRow);
      }
    } else {
      if (members && members.length > 0) {
        members.forEach( member => {
          var newRow = $("<div class='row align-items-center mt-2 member-row' data-member-name='" + member.name.toLowerCase() + "'>" +
                          "<div class='col-9'>" + member.name + "</div>" +
                          "<div class='col-3'>" +
                            "<a href='/admin/buildings/" + buildingId + "/businesses/" + businessId + "/add_member_to_building?member_id=" + member.id + "' data-method='post' data-remote='true' id='add_member_to_building_button_" + member.id + "' class='btn btn-addbuilding add-member-btn w-100 " + (member.access_member_building ? 'd-none' : '') + "'>Add</a>" +
                            "<a href='/admin/buildings/" + buildingId + "/businesses/" + businessId + "/remove_member_from_building?member_id=" + member.id + "' data-method='delete' data-remote='true' id='remove_member_from_building_button_" + member.id + "' class='btn bg-danger add-member-btn w-100 " + (member.access_member_building ? '' : 'd-none') + "'>Remove</a>" +
                          "</div>" +
                        "</div>");
      
          $("#members-list").append(newRow);
        });
      } else {
        var noMembersRow = $("<div class='row align-items-center mt-2 member-row'>" +
        "<div class='col-12 text-center'> There are no Members </div>" +
        "</div>");

        $("#members-list").append(noMembersRow);
      }
    }
  }

  const trafficChart = document.getElementById('trafficChart');
  var businessChart;

  var startDate = moment().startOf('month')
  var endDate = moment().endOf('month')


  var urlParams = new URLSearchParams(location.search);
 
  if ( urlParams.has('info') && urlParams.get('info') === 'report' ) {
    fetchAndUpdateChart();
  }

  $(function() {
    $('input[name="daterange-business"]').daterangepicker({
      startDate,
      endDate,
      opens: 'left',
      ranges: {
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, function(start, end, label) {
      startDate = formatDate(start)
      endDate = formatDate(end)

      fetchAndUpdateChart();
    });
  });

  $('#business_meeting_room_id').on('change', function() {
    fetchAndUpdateChart();
  })
  
  function fetchAndUpdateChart() {
    var meetingRoomId = $('#business_meeting_room_id').val()
    var buildingId = $('#business_building_id').val()
    var businessId = $('#business_meeting_room_id').data('business-id')

    var url = '/api/v1/admin/businesses/'+ encodeURIComponent(businessId) +'/report?start_date=' + encodeURIComponent(startDate) + '&end_date=' + encodeURIComponent(endDate) + '&meeting_room_id=' + encodeURIComponent(meetingRoomId) + '&building_id=' + encodeURIComponent(buildingId);

    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      success: function(response) {

        $('#total-meeting-count').text(response.meetings_count)
        updateMeetingsPerRoomTable(response.meetings_per_room)

        if (businessChart instanceof Chart) {
          businessChart.data.datasets[0].data = response.data
          businessChart.update()
        } else {
          businessChart = new Chart(trafficChart, {
            type: 'doughnut',
            options: {
              plugins: {
                tooltip: {
                  callbacks: {
                    afterLabel: function () {
                      return '%';
                    },
                  },
                },
              },
            },
            data: {
              labels: ['Used', 'Available'],
              datasets: [
                {
                  data: response.data,
                  backgroundColor: ['#F6976E', '#FDD7C7'],
                }
              ],
            },
            options: {
              plugins: {
                legend: {
                  labels: {
                    fontColor: '#F6976E',
                  }
                }
              }
            }
          });
        }
        if (isRunLegend) {
          toggleLegendAfterChart(legends);
          isRunLegend = false;
        }

      },
      error: function(error) {
        console.log(error)
      }
    })

  }


  function updateMeetingsPerRoomTable(meetingsPerRoom) {
    $('#meetings-per-room').empty();
    if (meetingsPerRoom.length > 0 ) {
      meetingsPerRoom.forEach( meetingRoom => {
        var newRow = '<tr>' + '<td><span>' + meetingRoom.name + '</span></td>' + '<td class="text-center text-white">' + meetingRoom.meetings_count + ' meetings </td>' + '</tr>'
        $('#meetings-per-room').append(newRow)
      })
    } else {
      var newRow = '<tr><td class="text-center text-white" colspan="2"><span> There are no Meetings </span></td></tr>'
      $('#meetings-per-room').append(newRow)
    }
  }

});

